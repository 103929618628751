import Statistics from '../../components/Statistic/Statistics';
import PageLayout from '../../components/layouts/PageLayout';

export default function Home() {
  // const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  // const { data, error, isLoading } = useSWR(
  //   `${SERVER_URL}/statistics`,
  //   fetcher
  // );
  const mockData = {
    orders: [
      {
        label: 'Broj porudžbina',
        value: 279,
      },
      {
        label: 'Aktivne porudžbine',
        value: 144,
      },
      {
        label: 'Završene porudžbine',
        value: 135,
      },
      {
        label: 'Ukupna vrednost porudžbina',
        value: 1132785,
        unit: 'RSD',
      },
    ],
    other: [
      {
        label: 'Broj kupaca',
        value: 46,
      },
      {
        label: 'Aktivne lokacije',
        value: 8,
      },
    ],
  };

  return (
    <PageLayout title={`Početna`}>
      {/* {error ? (
        <span>
          Desila se greška pri učitavanju statističkih podataka. Pokušajte
          ponovo.
        </span>
      ) : isLoading ? (
        <Spin />
      ) : ( */}
      <Statistics data={mockData} />
      {/* )} */}
    </PageLayout>
  );
}
