import useSWR from 'swr';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

const fetcher = ({ url, user }: { url: string; user: any }) =>
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    })
    .then((res) => res.data);

export const getAllMunicipalitiesBex = (user: any) => {
  const { data } = useSWR(
    { url: `${SERVER_URL}/municipality_bex`, user: user },
    fetcher
  );

  return {
    municipalitiesBex: data,
  };
};
