import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sidebar from '../Sidebar/Sidebar';

export default function Root() {
  return (
    <Layout>
      <Sidebar />
      <Content>
        <div className="h-screen overflow-auto px-8 py-4">
          <Suspense fallback={
            <div className='h-full w-full flex items-center justify-center'>
              Loading...
            </div>
          }>
            <Outlet />
          </Suspense>
        </div>
      </Content>
    </Layout>
  );
}
