import Button from '../Button/Button';

export type Props = {
  key: React.Key;
  children: React.ReactNode;
  toolFunction: () => void;
  style?: 'primary' | 'default' | 'text' | 'link';
  modifier?: object;
  hidden?: boolean;
};

export default function TableTool({
  children,
  toolFunction,
  style,
  modifier,
  hidden = false,
}: Props) {
  if (hidden) return null;
  return (
    <Button style={style} onClick={toolFunction} modifier={modifier}>
      {children}
    </Button>
  );
}
