import axios from 'axios';
import { KeyedMutator } from 'swr';
import { SERVER_URL } from '../../../config';
import { User } from '../../../types/User';

const deleteUserHandler = async (id: string, user: any) => {
  try {
    await axios.delete(`${SERVER_URL}/users/${id}`, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešno brisanje korisnika.');
  }
};

const deleteUser = async (
  users: User[],
  mutate: KeyedMutator<any>,
  user: any,
  authUser: any
) => {
  try {
    await mutate(deleteUserHandler(user._id, authUser), {
      optimisticData: [...users],
      rollbackOnError: true,
      populateCache: true,
      revalidate: true,
    });
  } catch (error) {
    console.error('Delete User Error: ', error);
    throw new Error('Neuspešno brisanje korisnika.');
  }
};
export default deleteUser;
