import { Dispatch, SetStateAction } from 'react';
import { Form, Input } from 'antd';
import { Modal as ModalProps, clientInitialState } from '../Clients';
import Button from '../../../components/Button/Button';
import { createClient, updateClient } from '../api';

export default function ClientForm({
  isNew = false,
  clientData,
  setModal,
  clientFormHandlerData,
}: {
  isNew: boolean | undefined;
  clientData: any;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  clientFormHandlerData: any;
}) {
  const handleSubmit = (values: any) => {
    isNew
      ? createClient(clientFormHandlerData, values)
      : updateClient(clientFormHandlerData, values, clientData._id);

    setModal({
      modalFor: '',
      clientData: clientInitialState,
      isNew: false,
    });
  };

  const initialValues = {
    clientName: clientData?.clientName,
    email: clientData?.email,
    phoneNumber: clientData?.phoneNumber,
    pib: clientData?.pib,
    city: clientData?.city,
    address: clientData?.address,
  };

  return (
    <Form
      onFinish={handleSubmit}
      className="flex flex-col"
      initialValues={initialValues}>
      <Form.Item
        name="clientId"
        rules={[
          { required: true, message: 'Molimo Vas unesite naziv klijenta!' },
        ]}>
        <Input placeholder="Šifra klijenta (npr. KL1234)" maxLength={10} />
      </Form.Item>
      <Form.Item
        name="clientName"
        rules={[
          { required: true, message: 'Molimo Vas unesite naziv klijenta!' },
        ]}>
        <Input placeholder="Naziv klijenta" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite e-mail adresu!',
          },
        ]}>
        <Input type="email" placeholder="E-mail adresa" />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite broj telefona!',
          },
        ]}>
        <Input placeholder="Broj telefona" />
      </Form.Item>
      <Form.Item
        name="pib"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite PIB!',
          },
        ]}>
        <Input type="number" placeholder="PIB" showCount />
      </Form.Item>
      <Form.Item
        name="city"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite grad!',
          },
        ]}>
        <Input placeholder="Grad" />
      </Form.Item>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite adresu!',
          },
        ]}>
        <Input placeholder="Adresa" />
      </Form.Item>
      <Button
        htmlType="submit"
        stretch
        style="primary" /*disabled={isLoading}*/
      >
        {isNew ? 'Kreiraj' : 'Izmeni'} klijenta
      </Button>
      {/* {error && <div>{error}</div>} */}
    </Form>
  );
}
