import { useRef, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { TableShipment, ViewModalShipment } from '../../types/Shipment';
import PageLayout from '../../components/layouts/PageLayout';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import getAllShipments from './api/getAllShipments';
import ViewShipment from './components/ViewShipment';
import OrderStatus from '../../components/Status/OrderStatus';
import { FaEye, FaSearch, FaStickyNote, FaSync } from 'react-icons/fa';
import syncShipments from './api/syncShipments';
import Button from '../../components/Button/Button';
import { DatePicker, Input, InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import getFormattedDate from '../../utils/getFormattedDate';
import TableActions from '../../components/Table/TableActions';
import TableAction from '../../components/Table/TableAction';
import ViewShipmentLabel from './components/ViewShipmentLabel';

type DataIndex = keyof TableShipment;

export type Modal = {
  modalFor: string;
  shipmentId: string;
};

export const modalInitialState: Modal = {
  modalFor: '',
  shipmentId: '',
};

export default function Shipments() {
  const { user } = useAuthContext();
  const [offset, setOffset] = useState(0);
  const { shipments, count, mutate } = getAllShipments(user, offset);
  const [modal, setModal] = useState<Modal>(modalInitialState);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedChangeDate, setSelectedChangeDate] = useState();
  const [syncLoading, setSyncLoading] = useState(false);

  const filteredShipments =
    selectedDate || selectedChangeDate
      ? shipments?.filter((o: any) => {
          const createdAtAsDate = new Date(o.createdAt);
          const lastUpdateAtAsDate = new Date(o.lastUpdate);
          const createdAtDateString = `${createdAtAsDate.getDate()}/${createdAtAsDate.getMonth()}/${createdAtAsDate.getFullYear()}`;
          const lastUpdateDateString = `${lastUpdateAtAsDate.getDate()}/${lastUpdateAtAsDate.getMonth()}/${lastUpdateAtAsDate.getFullYear()}`;
          let selectedDateString = '';
          let selectedLastUpdateString = '';

          if (selectedDate) {
            const selectedDateAsDate = new Date(selectedDate);
            selectedDateString = `${selectedDateAsDate.getDate()}/${selectedDateAsDate.getMonth()}/${selectedDateAsDate.getFullYear()}`;
          }
          if (selectedChangeDate) {
            const selectedChangeDateAsDate = new Date(selectedChangeDate);
            selectedLastUpdateString = `${selectedChangeDateAsDate.getDate()}/${selectedChangeDateAsDate.getMonth()}/${selectedChangeDateAsDate.getFullYear()}`;
          }

          if (selectedDate && !selectedChangeDate) {
            return createdAtDateString === selectedDateString;
          }
          if (!selectedDate && selectedChangeDate) {
            return lastUpdateDateString === selectedLastUpdateString;
          }
          if (selectedDate && selectedChangeDate) {
            return (
              createdAtDateString === selectedDateString &&
              lastUpdateDateString === selectedLastUpdateString
            );
          }
          if (!selectedDate && !selectedChangeDate) {
            return true;
          }
        })
      : shipments;

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  type filterDropdownProps = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: () => void;
    clearFilters: any;
    close: any;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: filterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pretraga`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button style="primary" onClick={() => handleSearch(confirm)}>
              Pretraži
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(confirm);
              }}>
              Resetuj
            </Button>
          </div>
          <Button
            style="text"
            onClick={() => {
              close();
            }}>
            Zatvori
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FaSearch style={{ color: filtered ? '#1D4ED8' : undefined }} />
    ),
    onFilter: (value: string, record: TableShipment) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filteredStatuses = shipments
    ?.map((s: TableShipment) => s.stateName)
    ?.filter(Boolean)
    ?.filter(
      (x: TableShipment, i: number, a: TableShipment[]) =>
        a.findIndex((s: TableShipment) => s.shipmentId === x.shipmentId) === i
    );

  const columns = [
    {
      key: 'shipmentId',
      dataIndex: 'shipmentId',
      title: 'ID Pošiljke',
    },
    {
      key: 'receiverName',
      dataIndex: 'receiverName',
      title: 'Primalac',
    },
    {
      key: 'receiverAddress',
      dataIndex: 'receiverAddress',
      title: 'Adresa primaoca',
    },
    {
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      title: 'Ukupan iznos',
    },
    {
      key: 'stateName',
      dataIndex: 'stateName',
      title: 'Status',
      render: (stateName: string) => <OrderStatus status={stateName} />,
      filters: filteredStatuses?.map((s: TableShipment) => ({
        text: s,
        value: s,
      })),
      onFilter: (value: string, record: TableShipment) =>
        record?.stateName?.startsWith(value),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Datum kreiranja',
      ...getColumnSearchProps('createdAt'),
      render: (createdAt: string) => <>{getFormattedDate(createdAt)}</>,
    },
    {
      key: 'lastUpdate',
      dataIndex: 'lastUpdate',
      title: 'Poslednja izmena',
      ...getColumnSearchProps('lastUpdate'),
      render: (lastUpdate: string) => (
        <> {lastUpdate ? getFormattedDate(lastUpdate) : '-'}</>
      ),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: ViewModalShipment) => (
        <TableActions>
          <TableAction
            action={() =>
              setModal({ modalFor: 'view', shipmentId: record.shipmentId })
            }>
            <FaEye title="Pregled pošiljke" />
          </TableAction>
          <TableAction
            action={() =>
              setModal({
                modalFor: 'viewShipmentLabel',
                shipmentId: record.shipmentId,
              })
            }>
            <FaStickyNote title="Preuzmi adresnicu" />
          </TableAction>
        </TableActions>
      ),
    },
  ];

  if (user?.role === 'superAdmin')
    columns.unshift(
      {
        key: 'senderName',
        dataIndex: 'senderName',
        title: 'Pošiljalac',
      },
      {
        key: 'senderAddress',
        dataIndex: 'senderAddress',
        title: 'Adresa pošiljaoca',
      }
    );

  const tools = [
    {
      key: '1',
      children: (
        <span className="flex items-center justify-between gap-2">
          <FaSync /> Sinhronizuj pošiljke
        </span>
      ),
      toolFunction: () => {
        syncShipments(user, setSyncLoading).then(mutate);
      },
    },
    {
      key: '2',
      children: (
        <DatePicker
          className="border-0 bg-transparent shadow-transparent"
          style={{ boxShadow: 'none' }}
          onChange={(value: any) => setSelectedDate(value)}
          format={'DD/MM/YYYY'}
          placeholder="Datum kreiranja"
          inputReadOnly
          showToday={false}
        />
      ),
      toolFunction: () => null,
      modifier: {
        padding: '0.25rem 0',
      },
    },
    {
      key: '3',
      children: (
        <DatePicker
          className="border-0 bg-transparent shadow-transparent"
          style={{ boxShadow: 'none' }}
          onChange={(value: any) => setSelectedChangeDate(value)}
          format={'DD/MM/YYYY'}
          placeholder="Datum izmene"
          inputReadOnly
          showToday={false}
        />
      ),
      toolFunction: () => null,
      modifier: {
        padding: '0.25rem 0',
      },
    },
  ];

  return (
    <PageLayout title="Pošiljke">
      <Table
        loading={syncLoading}
        columns={columns}
        data={filteredShipments}
        tools={tools}
        setOffset={setOffset}
        count={count}
      />
      <ViewShipment
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
      />
      <ViewShipmentLabel
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
      />
    </PageLayout>
  );
}
