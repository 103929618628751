import getAllClients from './getAllClients';
import createClient from './createClient';
import { updateClient, updateClientHandler } from './updateClient';
import deleteClient from './deleteClient';

export {
  getAllClients,
  createClient,
  updateClient,
  updateClientHandler,
  deleteClient,
};
