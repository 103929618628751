export const getAllClientsRoles = ['superAdmin', 'clientAdmin'];
export const getClientByIdRoles = ['superAdmin', 'clientAdmin'];
export const getClientSelectedCourierServicesRoles = [
  'superAdmin',
  'clientAdmin',
  'locationAdmin',
];

export const getAllLocationRoles = ['superAdmin', 'clientAdmin'];

export const resolveAllOrderMessages = ['superAdmin', 'clientAdmin'];

export const adminOrdersTableFunctions = ['superAdmin', 'clientAdmin'];
