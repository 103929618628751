import axios from 'axios';
import { PostClient } from '../../../types/Client';
import { SERVER_URL } from '../../../config';

const createClientHandler = async (data: PostClient, user: any) => {
  try {
    await axios.post(`${SERVER_URL}/clients`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešno dodavanje klijenta.');
  }
};

const createClient = async (clientFormHandlerData: any, clientData: PostClient) => {
  try {
    await clientFormHandlerData.mutate(
        createClientHandler(clientData, clientFormHandlerData.user),
      {
        optimisticData: [...clientFormHandlerData.clients, clientData],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Create Client Error: ', error);
    throw new Error('Neuspešno dodavanje klijenta.');
  }
};

export default createClient;
