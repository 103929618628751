import { Typography } from 'antd';

const { Title } = Typography;

type Props = {
  title: string;
  children: React.ReactNode;
};

export default function PageLayout({ title, children }: Props) {
  return (
    <>
      <Title level={1} className="min-w-max text-2xl sm:text-4xl">
        {title}
      </Title>
      {children}
    </>
  );
}
