import axios from 'axios';
import { User } from '../../../types/User';
import { SERVER_URL } from '../../../config';

const updateUserHandler = async (data: User, id: string, user: any) => {
  try {
    await axios.put(`${SERVER_URL}/users/${id}`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešna izmena korisnika.');
  }
};

const updateUser = async (
  userFormHandlerData: any,
  userData: User,
  id: string
) => {
  try {
    await userFormHandlerData.mutate(
      updateUserHandler(userData, id, userFormHandlerData.user),
      {
        optimisticData: [...userFormHandlerData.users],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Update User Error: ', error);
    throw new Error('Neuspešna izmena korisnika.');
  }
};

export default updateUser;
