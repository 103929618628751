import axios from 'axios';
import { SERVER_URL } from '../../../config';

const getShipmentLabel = async (id: string, user: any) => {
  try {
    const response = await axios.get(`${SERVER_URL}/bex/shipment-label/${id}`, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });

    return response.data.label;
  } catch (error) {
    console.log('error in getShipmentLabel: ', error);
  }
};

export default getShipmentLabel;
