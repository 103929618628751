import { Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import AuthPageLayout from '../../components/layouts/AuthPageLayout';
import Button from '../../components/Button/Button';

export default function NewPassword() {
  return (
    <AuthPageLayout title="Nova lozinka" logo>
      <Form
        onFinish={(values) => console.log(values)}
        className="flex flex-col">
        <Form.Item name="password">
          <Input type="password" placeholder="Lozinka" />
        </Form.Item>
        <Form.Item name="password-confirm" className="mb-4">
          <Input type="password" placeholder="Potvrdi lozinku" />
        </Form.Item>
        <div className="mb-6 flex justify-between">
          <Link to="/login" className="text-blue-700">
            Prijavi se
          </Link>
        </div>
        <Button htmlType="submit" stretch style="primary">
          Sačuvaj lozinku
        </Button>
      </Form>
    </AuthPageLayout>
  );
}
