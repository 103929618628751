import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Users';
import UserForm from './UserForm';
import Modal from '../../../components/Modal/Modal';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  userFormHandlerData: any;
  clients: any;
};

export default function EditUser({
  modal,
  setModal,
  modalInitialState,
  userFormHandlerData,
  clients,
}: Props) {
  const { user } = userFormHandlerData;

  return (
    <Modal
      open={modal?.modalFor === 'edit'}
      title={`${modal.isNew ? 'Kreiraj' : 'Izmena'} ${
        user?.role === 'superAdmin' && user?.role !== modal.userData?.role
          ? 'korisnika'
          : user?.role === modal.userData?.role
          ? 'podataka'
          : 'administratora lokacije'
      }`}
      onCancel={() => setModal(modalInitialState)}>
      <UserForm
        isNew={modal.isNew}
        userData={modal.userData}
        setModal={setModal}
        userFormHandlerData={userFormHandlerData}
        clients={clients}
      />
    </Modal>
  );
}
