import { Tag as AntTag, ButtonProps } from 'antd';
import { FaCircle } from 'react-icons/fa';

export enum TagColors {
  MAGENTA = 'magenta',
  RED = 'red',
  ORANGE = 'orange',
  GREEN = 'green',
  CYAN = 'cyan',
  BLUE = 'blue',
  GEEKBLUE = 'geekblue',
  PURPLE = 'purple',
  GRAY = '#eeeeee',
}

type Props = {
  color?: TagColors;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
} & ButtonProps;

export default function Tag({
  color = TagColors.GRAY,
  icon = <FaCircle />,
  className,
  children,
  ...props
}: Props) {
  return (
    <AntTag color={color} icon={icon} className={className} {...props}>
      {children}
    </AntTag>
  );
}
