import { useRef, useState } from 'react';
import { Input, InputRef } from 'antd';
import { FaEdit, FaTrash, FaSearch, FaPlus } from 'react-icons/fa';
import { Location } from '../../types/Location';
import { Client } from '../../types/Client';
import { useAuthContext } from '../../hooks/useAuthContext';
import TableActions from '../../components/Table/TableActions';
import TableAction from '../../components/Table/TableAction';
import PageLayout from '../../components/layouts/PageLayout';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Button from '../../components/Button/Button';
import { deleteLocation, getAllLocations } from './api';
import EditLocation from './components/EditLocation';
import DeleteLocation from './components/DeleteLocation';
import { getAllMunicipalitiesBex } from '../Location/api';
import { getAllClients } from '../Clients/api';
import { getAllMunicipalitiesDexpress } from './api/getAllMunicipalitiesDexpress';

type DataIndex = keyof Location;

export type Modal = {
  isNew?: boolean;
  modalFor: string;
  locationData: Location;
};

export const locationInitialState: Location = {
  client: {
    clientId: '',
    clientName: '',
    email: '',
    phoneNumber: '',
    pib: '',
  },
  locationName: '',
  email: [],
  phoneNumber: [],
};

export const modalInitialState: Modal = {
  isNew: false,
  modalFor: '',
  locationData: locationInitialState,
};

export default function Locations() {
  const { user } = useAuthContext();
  const { locations, mutate, isLoading, isError } = getAllLocations(user);
  const { municipalitiesBex } = getAllMunicipalitiesBex(user);
  const { municipalitiesDexpress } = getAllMunicipalitiesDexpress(user);

  const { clients } = getAllClients(user);
  const [modal, setModal] = useState<Modal>(modalInitialState);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  type filterDropdownProps = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: () => void;
    clearFilters: any;
    close: any;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: filterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pretraga`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button style="primary" onClick={() => handleSearch(confirm)}>
              Pretraži
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(confirm);
              }}>
              Resetuj
            </Button>
          </div>
          <Button
            style="text"
            onClick={() => {
              close();
            }}>
            Zatvori
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FaSearch style={{ color: filtered ? '#1D4ED8' : undefined }} />
    ),
    onFilter: (value: string, record: Location) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  // const filteredMunicipalities = locations
  //   ?.map((l: Location) => l.municipality?.MunicipalitiesName)
  //   ?.filter((x: Location, i: number, a: Location[]) => a.indexOf(x) === i);

  // const filteredPlaces = locations
  //   ?.map((l: Location) => l.place?.PlaceName)
  //   ?.filter((x: Location, i: number, a: Location[]) => a.indexOf(x) === i);

  const columns = [
    // {
    //   key: 'locationExternalId',
    //   dataIndex: 'locationExternalId',
    //   title: 'Šifra lokacije',
    //   ...getColumnSearchProps('locationExternalId'),
    // },
    {
      key: 'locationName',
      dataIndex: 'locationName',
      title: 'Ime lokacije',
      ...getColumnSearchProps('locationName'),
    },
    // {
    //   key: 'municipalities',
    //   dataIndex: ['municipality', 'MunicipalitiesName'],
    //   title: 'Opština',
    //   filters: filteredMunicipalities?.map((m: string) => ({
    //     text: m,
    //     value: m,
    //   })),
    //   onFilter: (value: string, record: Location) =>
    //     record?.municipality?.MunicipalitiesName?.startsWith(value),
    //   filterSearch: true,
    // },
    // {
    //   key: 'place',
    //   dataIndex: ['place', 'PlaceName'],
    //   title: 'Mesto',
    //   filters: filteredPlaces?.map((p: string) => ({
    //     text: p,
    //     value: p,
    //   })),
    //   onFilter: (value: string, record: Location) =>
    //     record?.place?.PlaceName?.startsWith(value),
    //   filterSearch: true,
    // },
    // {
    //   key: 'fullAddress',
    //   dataIndex: 'fullAddress',
    //   title: 'Adresa',
    //   ...getColumnSearchProps('fullAddress'),
    // },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: 'Broj telefona',
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'E-mail adresa',
      ...getColumnSearchProps('email'),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: Location) => (
        <TableActions>
          <TableAction
            action={() => setModal({ modalFor: 'edit', locationData: record })}>
            <FaEdit />
          </TableAction>
          <TableAction
            action={() =>
              setModal({ modalFor: 'delete', locationData: record })
            }>
            <FaTrash title="Brisanje lokacije" />
          </TableAction>
        </TableActions>
      ),
    },
  ];

  if (user?.role === 'superAdmin')
    columns.unshift({
      key: 'client',
      dataIndex: ['client', 'clientName'],
      title: 'Klijent',
      filters: clients?.map((c: Client) => ({
        text: c.clientName,
        value: c.clientName,
      })),
      onFilter: (value: string, record: Location) =>
        record?.client?.clientName?.startsWith(value),
      filterSearch: true,
    });

  const tools = [
    {
      key: '1',
      children: (
        <span className="flex items-center justify-between gap-2">
          <FaPlus /> Kreiraj lokaciju
        </span>
      ),
      toolFunction: () =>
        setModal({
          modalFor: 'edit',
          locationData: locationInitialState,
          isNew: true,
        }),
      style: 'primary' as 'primary',
    },
  ];

  return (
    <PageLayout title="Lokacije">
      {isError ? (
        <span>Desila se greška pri učitavanju podataka. Pokušajte ponovo.</span>
      ) : (
        <Table
          columns={columns}
          data={locations}
          tools={tools}
          loading={isLoading}
        />
      )}
      <EditLocation
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        locationFormHandlerData={{ locations, mutate, user }}
        municipalitiesBex={municipalitiesBex}
        municipalitiesDexpress={municipalitiesDexpress}
      />
      <DeleteLocation
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        deleteHandler={() => {
          deleteLocation(locations, mutate, modal.locationData, user);
          setModal(modalInitialState);
        }}
      />
    </PageLayout>
  );
}
