import { FaCheck, FaCircle } from 'react-icons/fa';
import { Tag as AntTag } from 'antd';
import Tag, { TagColors } from '../Tag/Tag';
// import { OrderStatuses } from '../../types/Order';

export default function OrderStatus({ status }: { status: string }) {
  // TODO complete all statuses
  switch (status) {
    case 'reception':
      return <Tag color={TagColors.GEEKBLUE}>Primljeno</Tag>;
    case 'dispatch':
      return <Tag color={TagColors.CYAN}>Poslato</Tag>;
    case 'delivered':
      return (
        <Tag color={TagColors.GREEN} icon={<FaCheck />}>
          Isporučeno
        </Tag>
      );
    case 'ready-for-delivery':
      return <Tag color={TagColors.MAGENTA}>Spremno za isporuku</Tag>;
    case 'Evidentirano':
      return <Tag color={TagColors.BLUE}>Evidentirano</Tag>;
    default:
      return (
        <AntTag className="default-tag" icon={<FaCircle />}>
          Nepoznato
        </AntTag>
      );
  }
}
