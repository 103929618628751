import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Orders';
import OrderForm from './OrderForm';
import Modal from '../../../components/Modal/Modal';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  orderFormHandlerData: any;
};

export default function EditOrder({
  modal,
  setModal,
  modalInitialState,
  orderFormHandlerData,
}: Props) {
  return (
    <Modal
      open={modal?.modalFor === 'edit'}
      title={`${modal.isNew ? 'Kreiraj porudžbinu' : 'Izmena porudžbine'}`}
      onCancel={() => setModal(modalInitialState)}>
      <OrderForm
        isNew={modal.isNew}
        orderData={modal.orderData}
        setModal={setModal}
        orderFormHandlerData={orderFormHandlerData}
      />
    </Modal>
  );
}
