import {
  FaUser,
  FaBriefcase,
  FaHome,
  FaMapMarkerAlt,
  FaEnvelope,
  FaCartArrowDown,
} from 'react-icons/fa';
import { Route } from '../types/Route';

export const ROUTES: Route[] = [
  {
    key: '/',
    icon: <FaHome />,
    label: 'Početna',
    link: '/',
    allowedRoles: ['superAdmin', 'clientAdmin', 'locationOfficer'],
  },
  {
    key: '/users',
    icon: <FaUser />,
    label: 'Korisnici',
    link: '/users',
    allowedRoles: ['superAdmin', 'clientAdmin'],
  },
  {
    key: '/clients',
    icon: <FaBriefcase />,
    label: 'Klijenti',
    link: '/clients',
    allowedRoles: ['superAdmin'],
  },
  {
    key: '/location',
    icon: <FaMapMarkerAlt />,
    label: 'Lokacije',
    link: '/location',
    allowedRoles: ['superAdmin', 'clientAdmin'],
  },
  {
    key: '/orders',
    icon: <FaCartArrowDown />,
    label: 'Porudžbine',
    link: '/orders',
    allowedRoles: ['superAdmin', 'clientAdmin', 'locationAdmin'],
  },
  {
    key: '/shipments',
    icon: <FaEnvelope />,
    label: 'Pošiljke',
    link: '/shipments',
    allowedRoles: ['superAdmin', 'clientAdmin', 'locationAdmin'],
  },
];
