import axios from 'axios';
import { KeyedMutator } from 'swr';
import { SERVER_URL } from '../../../config';
import { Location } from '../../../types/Location';

const deleteLocationHandler = async (id: string, user: any) => {
  try {
    await axios.delete(`${SERVER_URL}/locations/${id}`, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešno brisanje lokacije.');
  }
};

const deleteLocation = async (
  locations: Location[],
  mutate: KeyedMutator<any>,
  location: any,
  authUser: any
) => {
  try {
    await mutate(deleteLocationHandler(location.id, authUser), {
      optimisticData: [...locations],
      rollbackOnError: true,
      populateCache: true,
      revalidate: true,
    });
  } catch (error) {
    console.error('Delete Location Error: ', error);
    throw new Error('Neuspešno brisanje lokacije.');
  }
};
export default deleteLocation;
