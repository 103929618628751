import { Dispatch, SetStateAction, useState } from 'react';
import { Modal as ModalProps } from '../Shipments';
import Modal from '../../../components/Modal/Modal';
import getShipmentLabel from '../api/getShipmentLabel';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Button from '../../../components/Button/Button';
import { Spin } from 'antd';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
};

export default function ViewShipmentLabel({
  modal,
  setModal,
  modalInitialState,
}: Props) {
  const { user } = useAuthContext();
  const [shipmentLabelBase64, setShipmentLabelBase64] = useState();

  if (modal?.shipmentId) {
    getShipmentLabel(modal?.shipmentId, user)
      .then((shipmentLabel) => setShipmentLabelBase64(shipmentLabel))
      .catch((error) => console.log('error on getShipmentLabel: ', error));
  }

  return (
    <Modal
      open={modal?.modalFor === 'viewShipmentLabel'}
      title={`Adresnica za: ${modal?.shipmentId}`}
      onCancel={() => setModal(modalInitialState)}>
      {!shipmentLabelBase64 ? (
        <Spin />
      ) : (
        <div className="flex flex-col gap-4">
          <embed
            src={`data:application/pdf;base64,${shipmentLabelBase64}`}
            className="md:h-[400px]"
          />
          <Button disabled={!shipmentLabelBase64}>
            <a
              href={`data:application/pdf;base64,${shipmentLabelBase64}`}
              download={`adresnica-${modal?.shipmentId}.pdf`}>
              Preuzmi adresnicu{' '}
            </a>
          </Button>
        </div>
      )}
    </Modal>
  );
}
