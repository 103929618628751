import { Form, Input } from 'antd';
import { useLogin } from '../../../hooks/useLogin';
import Button from '../../../components/Button/Button';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
type SubmitProps = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const { login, error, isLoading } = useLogin();

  const handleLogin = ({ email, password }: SubmitProps) => {
    login({ email, password });
  };

  return (
    <Form onFinish={handleLogin} className="flex flex-col">
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite svoju e-mail adresu!',
          },
        ]}>
        <Input type="email" placeholder="E-mail adresa" />
      </Form.Item>
      <Form.Item
        name="password"
        className="mb-4"
        rules={[
          { required: true, message: 'Molimo Vas unesite svoju lozinku!' },
        ]}>
        <Input.Password placeholder="Lozinka" />
      </Form.Item>
      {/* <div className="mb-6 flex justify-between">
        <Link to="/forgot-password" className="text-blue-700">
          Zaboravio/la sam lozinku
        </Link>
      */}
      <Button htmlType="submit" stretch style="primary" disabled={isLoading}>
        Prijavi se
      </Button>
      {error && <ErrorMessage error={error} />}
    </Form>
  );
}
