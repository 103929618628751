import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import clsx from 'clsx';
import { FaSignOutAlt, FaAngleRight, FaAngleLeft, FaCog } from 'react-icons/fa';
import { ROUTES } from '../../config';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import Logo from '../Logo/Logo';
import Button from '../Button/Button';
import UserSettingsModal from './UserSettingsModal';

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const [mobile, setMobile] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  //user settings
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const handleLogout = () => {
    logout();
    navigate(0);
  };

  const items = ROUTES.map((route) => {
    const item = {
      key: route.key,
      icon: route.icon,
      label: <Link to={route.link}>{route.label}</Link>,
    };

    if (route.allowedRoles) {
      const allowed = route.allowedRoles?.includes(user?.role);

      if (!allowed) {
        return null;
      }
      return item;
    }

    return item;
  });

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      breakpoint="md"
      onBreakpoint={(broken) => {
        setMobile(broken);
      }}
      collapsedWidth={mobile ? 0 : '80px'}>
      <div
        className={clsx(
          'flex min-h-screen w-full flex-col justify-between border-r border-slate-300 bg-white p-4',
          {
            'p-0': mobile && collapsed,
          }
        )}>
        <div>
          <div className="mb-16 flex items-center justify-center">
            <Logo small={collapsed} link />
          </div>
          <div>
            <Menu
              theme="dark"
              selectedKeys={[pathname]}
              style={{ background: 'transparent' }}
              items={items}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {user?.role === 'clientAdmin' && (
            <Button stretch onClick={() => setShowSettingsModal(true)}>
              <FaCog /> {!collapsed && 'Podešavanja'}
            </Button>
          )}
          <Button stretch onClick={handleLogout}>
            <FaSignOutAlt /> {!collapsed && 'Odjavi se'}
          </Button>
          {mobile && collapsed ? (
            <Button
              className="fixed bottom-4 left-0 z-10 rounded-r bg-blue-700 px-3 py-2 text-white hover:bg-blue-600"
              style="text"
              stretch
              onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
            </Button>
          ) : mobile && !collapsed ? (
            <Button
              style="text"
              stretch
              onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
            </Button>
          ) : (
            <Button
              style="text"
              stretch
              onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <FaAngleRight /> : <FaAngleLeft />}
            </Button>
          )}
        </div>
      </div>
      {showSettingsModal && (
        <UserSettingsModal
          showSettingsModal={showSettingsModal}
          setShowSettingsModal={setShowSettingsModal}
        />
      )}
    </Sider>
  );
}
