import useSWR from 'swr';
import axios from 'axios';
import { SERVER_URL, TABLE_ROWS_COUNT } from '../../../config';

const fetcher = ({ url, user }: { url: string; user: any }) =>
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    })
    .then((res) => res.data);

const getAllShipments = (user: any, offset: number) => {
  const { data, mutate, error, isLoading } = useSWR(
    {
      url: `${SERVER_URL}/shipments?offset=${
        offset * TABLE_ROWS_COUNT
      }&limit=${TABLE_ROWS_COUNT}`,
      user: user,
    },
    fetcher
  );

  return {
    shipments: data?.items,
    count: data?.count,
    mutate: mutate,
    isLoading,
    isError: error,
  };
};

export default getAllShipments;
