import { Dispatch, Key, SetStateAction } from 'react';
import { Table as AntTable } from 'antd';
import NoData from './NoData';
import TableToolbar from './TableToolbar';
import { Props as TableTool } from './TableTool';
import { TABLE_ROWS_COUNT } from '../../config';

type SortOrder = 'descend' | 'ascend' | null;
type RecordType = object;

type Column = {
  key: string;
  dataIndex?: string;
  title: string;
  render?: (record?: any) => React.ReactNode;
  sorter?: object;
  sortDirections?: SortOrder[];
  defaultSortOrder?: SortOrder;
  onFilter?: (
    value: string | number | boolean | Key,
    record: RecordType
  ) => boolean;
};

type Props = {
  data?: object[];
  columns: Column[];
  loading?: boolean;
  rowCount?: number;
  tools?: TableTool[];
  setOffset?: Dispatch<SetStateAction<number>>;
  count?: number;
};

const locale = {
  emptyText: <NoData />,
};

export default function Table({
  data,
  columns,
  loading,
  rowCount = TABLE_ROWS_COUNT,
  tools,
  setOffset,
  count,
}: Props) {
  return (
    <>
      <TableToolbar toolsToShow={tools} />
      <AntTable
        className="overflow-auto"
        dataSource={data}
        columns={columns}
        rowKey="_id"
        loading={loading}
        // bordered
        pagination={{
          total: count,
          defaultPageSize: rowCount,
          position: ['bottomCenter'],
          showSizeChanger: false,
        }}
        onChange={(pagination) => {
          if (setOffset) setOffset(Number((pagination.current || 1) - 1));
        }}
        locale={locale}
      />
    </>
  );
}
