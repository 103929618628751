import { Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { useRegister } from '../../../hooks/useRegister';
import Button from '../../../components/Button/Button';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';

type SubmitProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function RegisterForm() {
  const { register, error, isLoading } = useRegister();

  const handleRegister = async ({
    email,
    password,
    firstName,
    lastName,
  }: SubmitProps) => {
    await register({
      email,
      password,
      firstName,
      lastName,
    });
  };

  return (
    <Form onFinish={handleRegister} className="flex flex-col">
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: 'Molimo Vas unesite svoje ime!' }]}>
        <Input placeholder="Ime" />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          { required: true, message: 'Molimo Vas unesite svoje prezime!' },
        ]}>
        <Input placeholder="Prezime" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Molimo Vas unesite svoju e-mail adresu!',
          },
        ]}>
        <Input type="email" placeholder="E-mail adresa" />
      </Form.Item>
      <Form.Item
        name="password"
        className="mb-4"
        rules={[{ required: true, message: 'Molimo Vas unesite lozinku!' }]}>
        <Input.Password placeholder='Lozinka' />
      </Form.Item>
      <Form.Item
        name="password-confirm"
        className="mb-4"
        rules={[
          { required: true, message: 'Molimo Vas unesite lozinku ponovo!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Morate uneti identičnu lozinku u oba polja!')
              );
            },
          }),
        ]}
        dependencies={['password']}>
        <Input.Password placeholder='Potvrdite lozinku' />
      </Form.Item>
      <div className="mb-6">
        <Link to="/login" className="text-blue-700">
          Već imam nalog
        </Link>
      </div>
      <Button htmlType="submit" stretch style="primary" disabled={isLoading}>
        Registruj se
      </Button>
      {error && <ErrorMessage error={error} />}
    </Form>
  );
}
