import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { getClientByIdRoles } from '../../../data/allowedRoles';

const getClientById = async (user: any) => {
  try {
    if (!getClientByIdRoles?.includes(user?.role)) {
      return null;
    }
    const res = await axios
      .get(`${SERVER_URL}/clients/${user?.client?._id}`, {
        headers: { Authorization: `Bearer ${user?.token}` },
        withCredentials: false,
      })
      .then((res) => res.data);

    return res;
  } catch (error) {
    console.log('error on getClientById: ', error);
    throw new Error('Serverska greška. Molimo pokušajte kasnije.');
  }
};

export default getClientById;
