import AuthPageLayout from '../../components/layouts/AuthPageLayout';
import RegisterForm from './components/RegisterForm';

export default function Register() {
  return (
    <AuthPageLayout title="Registracija" logo>
      <RegisterForm />
    </AuthPageLayout>
  );
}
