import { useAuthContext } from './useAuthContext';
import { AuthActionTypes } from '../context/AuthContext';

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = () => {
    sessionStorage.removeItem('user');

    dispatch({ type: AuthActionTypes.LOGOUT });
  };

  return { logout };
};
