import useSWR from 'swr';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { getAllLocationRoles } from '../../../data/allowedRoles';

const fetcher = ({ url, user }: { url: string; user: any }) =>
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    })
    .then((res) => res.data);

export const getAllLocations = (user: any) => {
  if (!getAllLocationRoles?.includes(user?.role)) {
    return {
      clients: null,
    };
  }
  const { data, mutate, error, isLoading } = useSWR(
    { url: `${SERVER_URL}/locations`, user: user },
    fetcher
  );

  return {
    locations: data,
    mutate: mutate,
    isLoading,
    isError: error,
  };
};
