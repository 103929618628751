import axios from 'axios';
import { PostClient } from '../../../types/Client';
import { SERVER_URL } from '../../../config';

export const updateClientHandler = async (
  data: PostClient,
  id: string,
  user: any
) => {
  try {
    await axios.put(`${SERVER_URL}/clients/${id}`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešna izmena korisnika.');
  }
};

export const updateClient = async (
  clientFormHandlerData: any,
  clientData: PostClient,
  id: string
) => {
  try {
    await clientFormHandlerData.mutate(
      updateClientHandler(clientData, id, clientFormHandlerData.user),
      {
        optimisticData: [...clientFormHandlerData.clients],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Update Client Error: ', error);
    throw new Error('Neuspešna izmena klijenta.');
  }
};
