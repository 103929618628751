import axios from 'axios';
import { Location } from '../../../types/Location';
import { SERVER_URL } from '../../../config';

const updateLocationHandler = async (
    user: any,
    id: string,
    data: Location,
) => {
    try {
        await axios.put(`${SERVER_URL}/locations/${id}`, data, {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
        });

    } catch (error) {
        throw new Error('Neuspešna izmena lokacije.');
    }
};

const updateLocation = async (
    locationFormHandlerData: any,
    locationData: Location,
    id: string
) => {
    try {
        await locationFormHandlerData.mutate(
            updateLocationHandler(locationFormHandlerData.user, id, locationData),
            {
                optimisticData: [...locationFormHandlerData.locations],
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    } catch (error) {
        console.error('Update Location Error: ', error);
        throw new Error('Neuspešna izmena lokacije.');
    }
};

export default updateLocation;
