import { Municipality } from '../../types/Location';

const shipmentTypeOptions = [
  { label: 'STANDARD', value: 1 },
  { label: 'SPECIAL', value: 3 },
  { label: 'INVOICE PERCENTAGE', value: 6 },
];

const shipmentCategoryOptions = [
  { label: '0 -> 0.5kg', value: 1 },
  { label: '0.51 -> 1kg', value: 2 },
  { label: '1.01 -> 2kg', value: 3 },
  { label: '2.01 -> 5kg', value: 4 },
  { label: '5.01 -> 10kg', value: 5 },
  { label: '10.01 -> 20kg', value: 7 },
  { label: '20.01 -> 30kg', value: 8 },
  { label: '30.01 -> 50kg', value: 9 },
  { label: '50.01 -> 70kg', value: 13 },
  { label: '70.01 -> 100kg', value: 14 },
  { label: '101 -> 200kg', value: 31 },
  { label: 'Po kilogramu (1 - 200kg)', value: 32 },
];

const payTypeOptions = [
  { label: 'Pošiljalac u kešu', value: 1 },
  { label: 'Poručilac u kešu', value: 2 },
  { label: 'Bezgotovinski kupac', value: 6 },
];

const preNotificationOptions = [
  { label: 'Bez obaveštenja', value: 0 },
  { label: '1 minut pre isporuke', value: 1 },
  { label: '5 minuta pre isporuke', value: 5 },
  { label: '15 minuta pre isporuke', value: 15 },
  { label: '30 minuta pre isporuke', value: 30 },
  { label: '45 minuta pre isporuke', value: 45 },
  { label: '1 sat pre isporuke', value: 60 },
];

const recipientTypeOptions = [
  { label: 'Fizičko lice', value: 1 },
  { label: 'Kompanija/organizacija', value: 2 },
  // { label: 'Šifra klijenta', value: 3 },
];

const getFormattedMunicipalityOptions = (municipalities: Municipality[]) => {
  return municipalities?.map(({ MunicipalitiesName }) => {
    return {
      label: MunicipalitiesName,
      value: MunicipalitiesName,
    };
  });
};

export {
  shipmentTypeOptions,
  shipmentCategoryOptions,
  payTypeOptions,
  preNotificationOptions,
  recipientTypeOptions,
  getFormattedMunicipalityOptions,
};
