import axios from 'axios';
import { notification } from 'antd';
import { SERVER_URL } from '../../../config';

const syncShipments = async (user: any, setSyncLoading: any) => {
  setSyncLoading(true);
  try {
    await axios.get(`${SERVER_URL}/bex/update-bex-shipments`, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });

    notification.success({
      message: 'Sinhronizacija pošiljki je uspešna.',
      placement: 'bottomRight',
    });
  } catch (error) {
    console.log('error in syncShipments: ', error);
    notification.error({
      message: `Neuspešna sinhronizacija pošiljki: ${error}`,
      placement: 'bottomRight',
    });
  } finally {
    setSyncLoading(false);
  }
};

export default syncShipments;
