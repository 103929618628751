import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Form, Input, Radio, RadioChangeEvent, Select, Typography } from 'antd';
import { Modal as ModalProps, userInitialState } from '../Users';
import { createUser, updateUser } from '../api';
import Button from '../../../components/Button/Button';
import { SERVER_URL } from '../../../config';
import axios from 'axios';

const { Text } = Typography;

export default function UserForm({
  isNew = false,
  userData,
  setModal,
  userFormHandlerData,
  clients,
}: {
  isNew: boolean | undefined;
  userData: any;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  userFormHandlerData: any;
  clients: any;
}) {
  const { user } = userFormHandlerData;

  // role field logic
  var initRole = isNew
    ? user?.role === 'superAdmin'
      ? 'clientAdmin'
      : 'locationAdmin'
    : userData?.role;

  const [role, setRole] = useState(initRole);
  const [client, setClient] = useState(user?.client?._id);
  const [locationsOfClient, setLocationsOfClient] = useState([]);

  useEffect(() => {
    if (client) {
      axios
        .get(`${SERVER_URL}/locations/${client}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
          withCredentials: false,
        })
        .then((res) => setLocationsOfClient(res.data));
    }
  }, [client]);

  const handleSubmit = (values: any) => {
    const role =
      user?.role === 'clientAdmin'
        ? 'locationAdmin'
        : values.role
        ? values.role
        : userData?.role;
    const client =
      role === 'locationAdmin' && user?.role !== 'superAdmin'
        ? user?.client?._id
        : values.client;
    const location = role === 'locationAdmin' ? values.location : undefined;
    const defaultLocation =
      role !== 'locationAdmin' ? values.defaultLocation : undefined;

    const submitData = { ...values, role, client, location, defaultLocation };

    isNew
      ? createUser(userFormHandlerData, submitData)
      : updateUser(userFormHandlerData, submitData, userData._id);

    setModal({
      modalFor: '',
      userData: userInitialState,
      isNew: false,
    });
  };

  const initialValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
    client: userData?.client?._id,
    location: userData?.location?._id,
    defaultLocation: userData?.defaultLocation?._id,
    role: userData?.role || role,
  };

  let roles = [
    {
      label: 'Admin',
      value: 'clientAdmin',
    },
    {
      label: 'Menadžer lokacije',
      value: 'locationAdmin',
    },
  ];

  if (user?.role === 'clientAdmin')
    roles = [
      {
        label: 'Menadžer lokacije',
        value: 'locationAdmin',
      },
    ];

  // role field logic
  const showRoleField = user?.role === 'superAdmin';
  // client field logic
  const showClientField =
    user?.role === 'superAdmin' && initialValues?.role !== 'superAdmin';
  // location field logic
  const showLocationField = role === 'locationAdmin';
  // default location field logic
  const showDefaultLocationField =
    (user?.role === 'superAdmin' || user?.id === userData?._id) &&
    role === 'clientAdmin';

  return (
    <Form
      onFinish={handleSubmit}
      className="flex flex-col"
      initialValues={initialValues}>
      <>
        <Text>Ime i prezime:</Text>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'Molimo Vas unesite ime!' }]}>
          <Input placeholder="Ime" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'Molimo Vas unesite prezime!' }]}>
          <Input placeholder="Prezime" />
        </Form.Item>
      </>
      {user?.id !== userData?._id && (
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Molimo Vas unesite e-mail adresu!',
            },
          ]}>
          <Input type="email" placeholder="E-mail adresa" />
        </Form.Item>
      )}
      {showClientField && (
        <>
          <Text>Klijent:</Text>
          <Form.Item
            name="client"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite klijenta!',
              },
            ]}>
            <Select
              placeholder="Klijent"
              onChange={(value) => setClient(value)}>
              {clients?.map((c: any) => (
                <Select.Option key={c._id} value={c._id}>
                  {c.clientName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      {showRoleField && (
        <>
          <Text>Uloga:</Text>
          <Form.Item
            name="role"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite ulogu!',
              },
            ]}>
            {/* <Select placeholder="Uloga" options={roles} /> */}
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={roles}
              onChange={(e: RadioChangeEvent) => setRole(e.target.value)}
            />
          </Form.Item>
        </>
      )}
      {showLocationField && (
        <>
          <Text>Lokacija:</Text>
          <Form.Item
            name="location"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite lokaciju!',
              },
            ]}>
            <Select placeholder="Lokacija">
              {locationsOfClient &&
                locationsOfClient?.map((l: any) => (
                  <Select.Option key={l.id} value={l.id}>
                    {l.locationName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </>
      )}
      {showDefaultLocationField && (
        <>
          <Text>Default lokacija:</Text>
          <Form.Item
            name="defaultLocation"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite default lokaciju!',
              },
            ]}>
            <Select
              placeholder="Lokacija"
              disabled={
                user?.id !== userData?._id && user?.role !== 'superAdmin'
              }>
              {locationsOfClient &&
                locationsOfClient?.map((l: any) => (
                  <Select.Option key={l.id} value={l.id}>
                    {l.locationName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </>
      )}
      {isNew && (
        <Form.Item
          name="password"
          className="mb-4"
          rules={[{ required: true, message: 'Molimo Vas unesite lozinku!' }]}>
          <Input placeholder="Lozinka" />
        </Form.Item>
      )}
      <Button
        htmlType="submit"
        stretch
        style="primary" /*disabled={isLoading}*/
      >
        {isNew ? 'Kreiraj korisnika' : 'Izmeni podatke'}
      </Button>
      {/* {error && <div>{error}</div>} */}
    </Form>
  );
}
