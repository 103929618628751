import { Card } from 'antd';
import { Typography } from 'antd';
import Logo from '../Logo/Logo';


const { Text } = Typography;

type Props = {
  logo?: boolean;
  title: string;
  children: React.ReactNode;
};

export default function AuthPageLayout({
  logo = false,
  title,
  children,
}: Props) {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-8 bg-slate-200">
      <div className="flex w-full justify-center px-4">
        <Card className="auth-card w-full max-w-[384px] rounded-xl shadow-md sm:w-96">
          {logo && <Logo />}
          <Text className="auth-card-title">{title}</Text>
          {children}
        </Card>
      </div>
    </div>
  );
}
