import { useRef, useState } from 'react';
import { Input, InputRef } from 'antd';
import { FaEdit, FaTrash, FaSearch, FaPlus } from 'react-icons/fa';
import { Client, PostClient } from '../../types/Client';
import { useAuthContext } from '../../hooks/useAuthContext';
import TableActions from '../../components/Table/TableActions';
import TableAction from '../../components/Table/TableAction';
import PageLayout from '../../components/layouts/PageLayout';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Button from '../../components/Button/Button';
import { deleteClient, getAllClients } from './api';
import EditClient from './components/EditClient';
import DeleteClient from './components/DeleteClient';

type DataIndex = keyof Client;

export type Modal = {
  isNew?: boolean;
  modalFor: string;
  clientData: PostClient;
};

export const clientInitialState: PostClient = {
  clientName: '',
  clientId: '',
  email: '',
  phoneNumber: '',
  pib: '',
  city: '',
  address: '',
};

export const modalInitialState: Modal = {
  isNew: false,
  modalFor: '',
  clientData: clientInitialState,
};

export default function Clients() {
  const { user } = useAuthContext();
  const { clients, mutate, isLoading, isError } = getAllClients(user);
  const [modal, setModal] = useState<Modal>(modalInitialState);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  type filterDropdownProps = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: () => void;
    clearFilters: any;
    close: any;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: filterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pretraga`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button style="primary" onClick={() => handleSearch(confirm)}>
              Pretraži
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(confirm);
              }}>
              Resetuj
            </Button>
          </div>
          <Button
            style="text"
            onClick={() => {
              close();
            }}>
            Zatvori
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FaSearch style={{ color: filtered ? '#1D4ED8' : undefined }} />
    ),
    onFilter: (value: string, record: Client) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      key: 'clientId',
      dataIndex: 'clientId',
      title: 'Šifra klijenta',
      ...getColumnSearchProps('clientId'),
    },
    {
      key: 'pib',
      dataIndex: 'pib',
      title: 'PIB',
      ...getColumnSearchProps('pib'),
    },
    {
      key: 'clientName',
      dataIndex: 'clientName',
      title: 'Naziv klijenta',
      sorter: {
        compare: (a: Client, b: Client) =>
          a.clientName.localeCompare(b.clientName),
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('clientName'),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'E-mail',
      ...getColumnSearchProps('email'),
    },
    {
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      title: 'Broj telefona',
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: PostClient) => (
        <TableActions>
          <TableAction
            action={() => setModal({ modalFor: 'edit', clientData: record })}>
            <FaEdit />
          </TableAction>
          <TableAction
            action={() => setModal({ modalFor: 'delete', clientData: record })}>
            <FaTrash title="Brisanje klijenta" />
          </TableAction>
        </TableActions>
      ),
    },
  ];

  const tools = [
    {
      key: '1',
      children: (
        <span className="flex items-center justify-between gap-2">
          <FaPlus /> Kreiraj klijenta
        </span>
      ),
      toolFunction: () =>
        setModal({
          modalFor: 'edit',
          clientData: clientInitialState,
          isNew: true,
        }),
      style: 'primary' as 'primary',
    },
  ];

  return (
    <PageLayout title="Klijenti">
      {isError ? (
        <span>Desila se greška pri učitavanju podataka. Pokušajte ponovo.</span>
      ) : (
        <Table
          columns={columns}
          data={clients}
          tools={tools}
          loading={isLoading}
        />
      )}
      <EditClient
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        clientFormHandlerData={{ clients, mutate, user }}
      />
      <DeleteClient
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        deleteHandler={() => {
          deleteClient(clients, mutate, modal.clientData, user);
          setModal(modalInitialState);
        }}
      />
    </PageLayout>
  );
}
