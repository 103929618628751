import axios from 'axios';
import { SERVER_URL } from '../../../config';

const updateOrderHandler = async (data: any, id: string, user: any) => {
  try {
    await axios.put(`${SERVER_URL}/orders/${id}`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešna izmena porudžbine.');
  }
};

const updateOrder = async (
  orderFormHandlerData: any,
  orderData: any,
  id: string
) => {
  try {
    await orderFormHandlerData.mutate(
      updateOrderHandler(orderData, id, orderFormHandlerData.user),
      {
        optimisticData: [...orderFormHandlerData.orders],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Update User Error: ', error);
    throw new Error('Neuspešna izmena porudžbine.');
  }
};

export default updateOrder;
