import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

type Props = {
  style?: 'primary' | 'default' | 'text' | 'link';
  modifier?: object;
  stretch?: boolean;
  htmlType?: 'submit' | 'button' | 'reset';
  children: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  style = 'default',
  modifier,
  stretch,
  children,
  htmlType,
  ...props
}: Props) {
  return (
    <button
      className={clsx(
        'flex items-center justify-center gap-2 rounded-lg px-3 py-2 transition-colors',
        {
          'bg-blue-700 font-semibold text-white hover:bg-blue-600':
            style === 'primary',
          'border border-blue-700 bg-transparent text-blue-700 hover:border-blue-600 hover:bg-blue-50 hover:text-blue-500':
            style === 'default',
          'text-blue-700 hover:text-blue-600': style === ('text' || 'link'),
          underline: style === 'link',
          'flex-1': stretch,
        }
      )}
      style={modifier}
      type={htmlType}
      {...props}>
      {children}
    </button>
  );
}
