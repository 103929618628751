import axios from 'axios';
import { SERVER_URL } from '../../../config';

const updateClientCourierServices = async (values: any, user: any) => {
  try {
    console.log('values: ', values);
    await axios.put(
      `${SERVER_URL}/clients/${user?.client?._id}`,
      {
        courierPricingDetails: values,
      },
      {
        headers: { Authorization: `Bearer ${user?.token}` },
        withCredentials: false,
      }
    );
  } catch (error) {
    console.log('error on updateClientCourierServices: ', error);
    throw new Error('Serverska greška. Molimo pokušajte kasnije.');
  }
};

export default updateClientCourierServices;
