import axios from 'axios';
import { notification } from 'antd';
import { SERVER_URL } from '../../../config';
import { Modal, modalInitialState } from '../Orders';

const createShipmentFromOrder = async (
  user: any,
  modal: Modal,
  setModal: any,
  setLoading: any
) => {
  setLoading(true);
  try {
    await axios.post(
      `${SERVER_URL}/orders/send-to-courier/${modal?.orderData?._id}`,
      {},
      {
        headers: { Authorization: `Bearer ${user?.token}` },
        withCredentials: false,
      }
    );
    notification.success({
      message: 'Kreirana pošiljka',
      placement: 'bottomRight',
    });
  } catch (error) {
    console.log('error in handleCreateShipment: ', error);
    notification.error({
      message: `Neuspešno kreiranje pošiljke: ${error}`,
      placement: 'bottomRight',
    });
  } finally {
    setLoading(false);
    setModal(modalInitialState);
  }
};

export default createShipmentFromOrder;
