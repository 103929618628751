import axios from 'axios';
import { KeyedMutator } from 'swr';
import { SERVER_URL } from '../../../config';
import { Client } from '../../../types/Client';

const deleteClientHandler = async (id: string, user: any) => {
  try {
    await axios.delete(`${SERVER_URL}/clients/${id}`, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešno brisanje klijenta.');
  }
};

const deleteClient = async (
  clients: Client[],
  mutate: KeyedMutator<any> | undefined,
  client: any,
  authUser: any
) => {
  try {
    if (mutate) {
      await mutate(deleteClientHandler(client._id, authUser), {
        optimisticData: [...clients],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      });
    }
  } catch (error) {
    console.error('Delete Client Error: ', error);
    throw new Error('Neuspešno brisanje klijenta.');
  }
};
export default deleteClient;
