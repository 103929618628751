import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Orders';
import Modal from '../../../components/Modal/Modal';
import { Form, Input, Tooltip, Typography } from 'antd';
import Button from '../../../components/Button/Button';
import updateOrder from '../api/updateOrder';
import { ChatMessage } from '../../../types/Order';
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { resolveAllOrderMessages } from '../../../data/allowedRoles';
import clsx from 'clsx';
import MessageMeta from './MessageMeta';

const { Text } = Typography;

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  orderFormHandlerData: any;
};

export default function OrderChat({
  modal,
  setModal,
  modalInitialState,
  orderFormHandlerData,
}: Props) {
  const handleSendMessage = (values: any) => {
    const orderDataCopy = { ...modal?.orderData };
    const chatMessagesCopy =
      modal?.orderData?.chatMessages &&
      modal?.orderData?.chatMessages?.length > 0
        ? [...modal?.orderData?.chatMessages]
        : [];

    const messageToSubmit = {
      userId: orderFormHandlerData?.user?.id,
      message: values?.chatMessage,
      done: false,
    };

    const updatedOrder = {
      ...orderDataCopy,
      chatMessages: [...chatMessagesCopy, messageToSubmit],
    };

    updateOrder(orderFormHandlerData, updatedOrder, modal?.orderData?._id);
    setModal(modalInitialState);
  };

  const handleResolveMessage = (messageId: string | undefined) => {
    if (!messageId) return null;

    const orderDataCopy = { ...modal?.orderData };
    const updatedChatMessages: ChatMessage[] = orderDataCopy?.chatMessages?.map(
      (message: ChatMessage) => {
        if (message._id === messageId) message.done = true;

        return message;
      }
    );

    orderDataCopy.chatMessages = updatedChatMessages;

    updateOrder(orderFormHandlerData, orderDataCopy, modal?.orderData?._id);
  };

  return (
    <Modal
      title="Poruke"
      open={modal?.modalFor === 'chat'}
      onCancel={() => setModal(modalInitialState)}>
      {modal?.orderData?.chatMessages &&
        modal?.orderData?.chatMessages?.length > 0 && (
          <div className="mb-4 flex flex-col items-start gap-8 border-b pb-4">
            {modal?.orderData?.chatMessages?.map((chatMessage: ChatMessage) => {
              // user messages
              if (chatMessage?.userId === orderFormHandlerData?.user?.id) {
                return (
                  <div className="relative self-end" key={chatMessage?._id}>
                    {/* message meta */}
                    <MessageMeta
                      orderFormHandlerData={orderFormHandlerData}
                      userId={chatMessage?.userId}
                      messageCreatedAt={chatMessage?.createdAt}
                      rightPosition
                    />

                    {/* message content */}
                    <div className="flex items-center gap-4 rounded bg-blue-600 p-2 text-white">
                      {!chatMessage?.done ? (
                        <div
                          className="cursor-pointer text-lg text-gray-400 hover:text-gray-100"
                          onClick={() =>
                            handleResolveMessage(chatMessage?._id)
                          }>
                          <Tooltip title="Označi kao rešeno">
                            <FaRegCheckCircle />
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="text-lg text-green-500">
                          <Tooltip title="Rešeno">
                            <FaCheckCircle />
                          </Tooltip>
                        </div>
                      )}
                      {chatMessage.message}
                    </div>
                  </div>
                );
              }
              return (
                <div className="relative" key={chatMessage?._id}>
                  {/* message meta */}
                  <MessageMeta
                    orderFormHandlerData={orderFormHandlerData}
                    userId={chatMessage?.userId}
                    messageCreatedAt={chatMessage?.createdAt}
                  />

                  {/* message content */}
                  <div className="flex items-center gap-4 rounded bg-gray-600 p-2 text-white">
                    {!chatMessage?.done ? (
                      <div
                        className={clsx(
                          'cursor-pointer text-lg text-gray-400 hover:text-gray-100',
                          {
                            'cursor-default': !resolveAllOrderMessages.includes(
                              orderFormHandlerData?.user?.role
                            ),
                          }
                        )}
                        onClick={() =>
                          resolveAllOrderMessages.includes(
                            orderFormHandlerData?.user?.role
                          )
                            ? handleResolveMessage(chatMessage?._id)
                            : null
                        }>
                        {resolveAllOrderMessages.includes(
                          orderFormHandlerData?.user?.role
                        ) ? (
                          <Tooltip title="Označi kao rešeno">
                            <FaRegCheckCircle />
                          </Tooltip>
                        ) : (
                          <FaRegCheckCircle />
                        )}
                      </div>
                    ) : (
                      <div className="text-lg text-green-500">
                        <Tooltip title="Rešeno">
                          <FaCheckCircle />
                        </Tooltip>
                      </div>
                    )}
                    {chatMessage.message}
                  </div>
                </div>
              );
            })}
          </div>
        )}

      <Form onFinish={handleSendMessage}>
        <>
          <Text>Poruka:</Text>
          <Form.Item name="chatMessage">
            <Input.TextArea
              maxLength={1000}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Form.Item>
        </>
        <Button htmlType="submit" style="primary">
          Pošalji
        </Button>
      </Form>
    </Modal>
  );
}
