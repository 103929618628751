import { Dispatch, SetStateAction, useState } from 'react';
import { Typography } from 'antd';
import { Modal as ModalProps } from '../Shipments';
import Modal from '../../../components/Modal/Modal';
import { useAuthContext } from '../../../hooks/useAuthContext';
import getShipmentInfo from '../api/getShipmentInfo';

const { Paragraph, Text } = Typography;

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
};

export default function ViewShipment({
  modal,
  setModal,
  modalInitialState,
}: Props) {
  const { user } = useAuthContext();
  const [shipment, setShipment] = useState();

  if (modal?.shipmentId) {
    getShipmentInfo(modal.shipmentId, user)
      .then((shipmentInfo) => setShipment(shipmentInfo))
      .catch((error) => console.log('error in getShipmentInfo: ', error));
  }

  return (
    <Modal
      open={modal?.modalFor === 'view'}
      title={`Pregled pošiljke: ${modal?.shipmentId}`}
      onCancel={() => setModal(modalInitialState)}>
      <Paragraph>
        <Text>
          <pre>{shipment || ''}</pre>
        </Text>
      </Paragraph>
    </Modal>
  );
}
