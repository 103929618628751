import { Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import AuthPageLayout from '../../components/layouts/AuthPageLayout';
import Button from '../../components/Button/Button';

export default function ForgotPassword() {
  return (
    <AuthPageLayout title="Zaboravio/la sam lozinku" logo>
      <Form
        onFinish={(values) => console.log(values)}
        className="flex flex-col">
        <Form.Item name="password" className="mb-4">
          <Input type="email" placeholder="E-mail adresa" />
        </Form.Item>
        <div className="mb-6 flex justify-between">
          <Link to="/login" className="text-blue-700">
            Nazad na prijavu
          </Link>
        </div>
        <Button htmlType="submit" stretch style="primary">
          Pošalji zahtev za novu lozinku
        </Button>
      </Form>
    </AuthPageLayout>
  );
}
