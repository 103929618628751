import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Clients';
import ClientForm from './ClientForm';
import Modal from '../../../components/Modal/Modal';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  clientFormHandlerData: any;
};

export default function EditClient({
  modal,
  setModal,
  modalInitialState,
  clientFormHandlerData,
}: Props) {
  return (
    <Modal
      open={modal?.modalFor === 'edit'}
      title={`${modal.isNew ? 'Kreiraj' : 'Izmena'} klijenta`}
      onCancel={() => setModal(modalInitialState)}>
      <ClientForm
        isNew={modal.isNew}
        clientData={modal.clientData}
        setModal={setModal}
        clientFormHandlerData={clientFormHandlerData}
      />
    </Modal>
  );
}
