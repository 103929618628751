import { Result } from 'antd';
import { Link } from 'react-router-dom';
import Button from '../components/Button/Button';


export default function NoMatch() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Stranica koju pokušavate da posetite ne postoji."
      extra={
        <Link to="/" className="flex justify-center">
          <Button style="primary">Povratak na početnu</Button>
        </Link>
      }
    />
  );
}
