import {
  FaCheck,
  FaComment,
  FaEdit,
  FaEnvelope,
  FaEye,
  FaTimes,
} from 'react-icons/fa';
import getFormattedDate from '../../utils/getFormattedDate';
import { TableOrder } from '../../types/Order';
import TableActions from '../../components/Table/TableActions';
import TableAction from '../../components/Table/TableAction';
import Tag, { TagColors } from '../../components/Tag/Tag';
import { Tooltip } from 'antd';

export const defaultColumns = (
  getColumnSearchProps: any,
  setModal: any,
  setShipmentModal: any
) => {
  return [
    {
      key: 'recipientContact',
      dataIndex: 'recipientContact',
      title: 'Poručilac',
      ...getColumnSearchProps('recipientContact'),
    },
    {
      key: 'recipientPhone',
      dataIndex: 'recipientPhone',
      title: 'Broj telefona',
      ...getColumnSearchProps('recipientPhone'),
    },
    {
      key: 'recipientCity',
      dataIndex: 'recipientCity',
      title: 'Grad',
      ...getColumnSearchProps('recipientCity'),
    },
    {
      key: 'address',
      title: 'Adresa',
      render: (record: TableOrder) => (
        <span className="flex items-center justify-between gap-3">
          {`${record.recipientAddress} ${record.recipientAddressNumber}${
            record.recipientApartmentNumber
              ? '/' + record.recipientApartmentNumber
              : ''
          }`}
          {record.isAddressFound ? (
            <Tooltip title="Adresa je validna.">
              <Tag
                color={TagColors.GREEN}
                icon={<FaCheck />}
                className="m-0"></Tag>
            </Tooltip>
          ) : (
            <Tooltip title="Adresa nije prepoznata. Proverite podatke: Opština, Mesto i Ulica.">
              <Tag
                color={TagColors.RED}
                icon={<FaTimes />}
                className="m-0 cursor-pointer"
                onClick={() =>
                  setModal({ modalFor: 'edit', orderData: record })
                }></Tag>
            </Tooltip>
          )}
        </span>
      ),
      ...getColumnSearchProps('recipientAddress'),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Datum kreiranja',
      ...getColumnSearchProps('createdAt'),
      render: (createdAt: string) => <>{getFormattedDate(createdAt)}</>,
    },
    {
      key: 'shipmentId',
      dataIndex: 'shipmentId',
      title: 'Povezana pošiljka',
      render: (shipmentId: string) =>
        shipmentId ? (
          <TableAction
            action={() =>
              setShipmentModal({
                modalFor: 'view',
                shipmentId: shipmentId,
              })
            }>
            {shipmentId}
          </TableAction>
        ) : (
          '-'
        ),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: TableOrder) => (
        <TableActions>
          <TableAction
            action={() => setModal({ modalFor: 'view', orderData: record })}>
            <FaEye />
          </TableAction>
          <TableAction
            action={() => setModal({ modalFor: 'edit', orderData: record })}>
            <FaEdit />
          </TableAction>
          <TableAction
            action={() =>
              setModal({ modalFor: 'createShipment', orderData: record })
            }>
            <FaEnvelope />
          </TableAction>
          <TableAction
            action={() => setModal({ modalFor: 'chat', orderData: record })}>
            <FaComment />
          </TableAction>
        </TableActions>
      ),
    },
  ];
};

export const defaultColumnsAdmin = (
  getColumnSearchProps: any,
  setModal: any,
  setShipmentModal: any
) => {
  return [
    {
      key: 'recipientContact',
      dataIndex: 'recipientContact',
      title: 'Poručilac',
      ...getColumnSearchProps('recipientContact'),
    },
    {
      key: 'recipientPhone',
      dataIndex: 'recipientPhone',
      title: 'Broj telefona',
      ...getColumnSearchProps('recipientPhone'),
    },
    {
      key: 'recipientCity',
      dataIndex: 'recipientCity',
      title: 'Grad',
      ...getColumnSearchProps('recipientCity'),
    },
    {
      key: 'recipientZipCode',
      dataIndex: 'recipientZipCode',
      title: 'Poštanski kod',
      ...getColumnSearchProps('recipientZipCode'),
    },
    {
      key: 'address',
      title: 'Adresa',
      render: (record: TableOrder) => (
        <span className="flex items-center justify-between gap-3">
          {`${record.recipientAddress} ${record.recipientAddressNumber}${
            record.recipientApartmentNumber
              ? '/' + record.recipientApartmentNumber
              : ''
          }`}
          {record.isAddressFound ? (
            <Tooltip title="Adresa je validna.">
              <Tag
                color={TagColors.GREEN}
                icon={<FaCheck />}
                className="m-0"></Tag>
            </Tooltip>
          ) : (
            <Tooltip title="Adresa nije prepoznata. Proverite podatke: Opština, Mesto i Ulica.">
              <Tag
                color={TagColors.RED}
                icon={<FaTimes />}
                className="m-0 cursor-pointer"
                onClick={() =>
                  setModal({ modalFor: 'edit', orderData: record })
                }></Tag>
            </Tooltip>
          )}
        </span>
      ),
      ...getColumnSearchProps('recipientAddress'),
    },
    {
      key: 'payToSender',
      dataIndex: 'payToSender',
      title: 'Iznos',
      render: (payToSender: number) => <span>{`${payToSender} RSD`}</span>,
      ...getColumnSearchProps('recipientAddress'),
    },
    {
      key: 'recipientComment',
      dataIndex: 'recipientComment',
      title: 'Komentar',
      ...getColumnSearchProps('recipientComment'),
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Datum kreiranja',
      ...getColumnSearchProps('createdAt'),
      render: (createdAt: string) => <>{getFormattedDate(createdAt)}</>,
    },
    {
      key: 'shipmentId',
      dataIndex: 'shipmentId',
      title: 'Povezana pošiljka',
      render: (shipmentId: string) =>
        shipmentId ? (
          <TableAction
            action={() =>
              setShipmentModal({
                modalFor: 'view',
                shipmentId: shipmentId,
              })
            }>
            {shipmentId}
          </TableAction>
        ) : (
          '-'
        ),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: TableOrder) => (
        <TableActions>
          <TableAction
            action={() => setModal({ modalFor: 'view', orderData: record })}>
            <FaEye />
          </TableAction>
          <TableAction
            action={() => setModal({ modalFor: 'edit', orderData: record })}>
            <FaEdit />
          </TableAction>
          <TableAction
            action={() =>
              setModal({ modalFor: 'createShipment', orderData: record })
            }>
            <FaEnvelope />
          </TableAction>
          <TableAction
            action={() => setModal({ modalFor: 'chat', orderData: record })}>
            <FaComment />
          </TableAction>
        </TableActions>
      ),
    },
  ];
};
