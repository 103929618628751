import { createContext, useEffect, useReducer } from 'react';
import { SessionClient } from '../types/Client';
import { SessionLocation } from '../types/Location';

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

// TODO: Define payload more precisely
type UserAction = {
  type: AuthActionTypes;
  payload: any;
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  role: string;
  client: SessionClient;
  location: SessionLocation;
};

type Dispatch = {
  type: AuthActionTypes;
  payload?: User;
};

type TAuthContext = {
  user: User;
  dispatch: (arg: Dispatch) => void;
  userRole: string;
};

// TODO: Add user type and set it as defaultValue
export const AuthContext = createContext<TAuthContext>({} as TAuthContext);

export const authReducer = (state: any, action: UserAction) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { user: action.payload };
    case AuthActionTypes.LOGOUT:
      return { user: null };
    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

export const AuthContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: JSON.parse(sessionStorage.getItem('user') || '{}'),
  });

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user') || '{}');

    if (Object.keys(user).find((key) => key === 'token')) {
      dispatch({ type: AuthActionTypes.LOGIN, payload: user });
    }
  }, []);

  console.log('AuthContext state: ', state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
