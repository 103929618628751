import { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../config';
import { useAuthContext } from './useAuthContext';
import { AuthActionTypes } from '../context/AuthContext';

type Props = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export const useRegister = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatch } = useAuthContext();

  const register = async ({ email, password, firstName, lastName }: Props) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${SERVER_URL}/register`, {
        email,
        password,
        firstName: firstName,
        lastName: lastName,
        role: 'superAdmin',
      });

      if (response.statusText !== 'Created') {
        setIsLoading(false);
        // TODO handle error
        setError(response.data.message);
      }

      if (response.statusText === 'Created') {
        const user = {
          email: response?.data?.user?.email,
          firstName: response?.data?.user?.firstName,
          lastName: response?.data?.user?.lastName,
          token: response?.data?.token,
          role: response?.data?.user?.role,
          client: response?.data?.user?.client,
        };

        setIsLoading(false);
        sessionStorage.setItem('user', JSON.stringify(user));

        dispatch({ type: AuthActionTypes.LOGIN, payload: user });
      }
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message);
    }
  };

  return { register, isLoading, error };
};
