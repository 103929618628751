import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Users';
import Modal from '../../../components/Modal/Modal';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  deleteHandler: any;
};

export default function DeleteUser({
  modal,
  setModal,
  modalInitialState,
  deleteHandler,
}: Props) {
  return (
    <Modal
      open={modal?.modalFor === 'delete'}
      title="Brisanje korisnika"
      showActions
      onCancel={() => setModal(modalInitialState)}
      onOk={deleteHandler}>
      Da li ste sigurni da želite da obrišete{' '}
      <span className="font-bold">
        {modal.userData.firstName} {modal.userData.lastName}
      </span>
      ?
    </Modal>
  );
}
