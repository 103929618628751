import Statistic, { Props as StatisticProps } from './Statistic';

export default function Statistics({ data }: { data: any }) {
  return (
    <div>
      {Object.keys(data).map((key) => (
        <div
          key={key}
          className="mb-4 max-w-5xl border-b border-slate-300 pb-4">
          <div className="flex flex-wrap gap-4">
            {data[key].map((statistic: StatisticProps) => (
              <Statistic
                key={statistic.label}
                label={statistic.label}
                value={statistic.value}
                unit={statistic.unit}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
