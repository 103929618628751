import { useState } from 'react';
import getUserById from '../../Users/api/getUserById';
import clsx from 'clsx';

type Props = {
  orderFormHandlerData: any;
  userId: string;
  messageCreatedAt: string | undefined;
  rightPosition?: boolean;
};

export default function MessageMeta({
  orderFormHandlerData,
  userId,
  messageCreatedAt,
  rightPosition = false,
}: Props) {
  const date = new Date(messageCreatedAt || '2000-01-01T00:00:00.000Z');
  const formattedDate = new Intl.DateTimeFormat('rs-SR', {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: false,
  }).format(date);

  const [authorLoading, setAuthorLoading] = useState<boolean>(true);
  const [authorToDisplay, setAuthorToDisplay] = useState<string>('');

  const getMessageAuthor = async () => {
    try {
      const messageAuthor = await getUserById(
        userId,
        orderFormHandlerData?.user
      );
      return messageAuthor;
    } catch (error) {
      throw error;
    }
  };

  const prepareMessageAuthor = async () => {
    try {
      const author = await getMessageAuthor();

      return `${author?.firstName} ${author?.lastName}`;
    } catch (error) {
      throw error;
    }
  };

  prepareMessageAuthor()
    .then((res: string) => {
      setAuthorToDisplay(res);
    })
    .finally(() => {
      setAuthorLoading(false);
    });

  if (authorLoading) {
    return (
      <span
        className={clsx('absolute top-[-1.125rem] flex w-max gap-1 text-xs', {
          'right-0': rightPosition,
        })}>
        ... {formattedDate}
      </span>
    );
  }

  return (
    <span
      className={clsx('absolute top-[-1.125rem] flex w-max gap-1 text-xs', {
        'right-0': rightPosition,
      })}>
      {authorToDisplay} {formattedDate}
    </span>
  );
}
