import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../config';
import { useAuthContext } from './useAuthContext';
import { AuthActionTypes } from '../context/AuthContext';

type Props = {
  email: string;
  password: string;
};

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const login = async ({ email, password }: Props) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${SERVER_URL}/login`, {
        email,
        password,
      });

      if (response?.status !== 201) {
        setIsLoading(false);
        // TODO handle error properly
        setError(response?.data?.message);
      }

      if (response?.status === 201) {
        const user = {
          id: response?.data?.user?._id,
          email: response?.data?.user?.email,
          firstName: response?.data?.user?.firstName,
          lastName: response?.data?.user?.lastName,
          token: response?.data?.token,
          role: response?.data?.user?.role,
          client: response?.data?.user?.client,
          location: response?.data?.user?.location,
        };

        setIsLoading(false);
        sessionStorage.setItem('user', JSON.stringify(user));

        dispatch({ type: AuthActionTypes.LOGIN, payload: user });
        if (user?.token) {
          navigate('/');
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message);
    }
  };

  return { login, isLoading, error };
};
