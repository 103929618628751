import { Dispatch, SetStateAction } from 'react';
import { Modal as ModalProps } from '../Location';
import LocationForm from './LocationForm';
import Modal from '../../../components/Modal/Modal';

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  locationFormHandlerData: any;
  municipalitiesBex: any;
  municipalitiesDexpress: any;
};

export default function EditLocation({
  modal,
  setModal,
  modalInitialState,
  locationFormHandlerData,
  municipalitiesBex,
  municipalitiesDexpress,
}: Props) {
  return (
    <Modal
      open={modal?.modalFor === 'edit'}
      title={`${modal.isNew ? 'Kreiraj' : 'Izmeni'} lokaciju`}
      onCancel={() => setModal(modalInitialState)}>
      <LocationForm
        isNew={modal.isNew}
        locationData={modal.locationData}
        setModal={setModal}
        locationFormHandlerData={locationFormHandlerData}
        municipalitiesBex={municipalitiesBex}
        municipalitiesDexpress={municipalitiesDexpress}
      />
    </Modal>
  );
}
