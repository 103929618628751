import useSWR from 'swr';
import axios from 'axios';
import { SERVER_URL } from '../../../config';

const orderTableRowCount = 10;

const fetcher = ({ url, user }: { url: string; user: any }) =>
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    })
    .then((res) => res.data);

const getAllOrders = (user: any, offset: number) => {
  const { data, mutate, error, isLoading } = useSWR(
    {
      url: `${SERVER_URL}/orders?offset=${
        offset * orderTableRowCount
      }&limit=${orderTableRowCount}`,
      user: user,
    },
    fetcher
  );

  return {
    orders: data?.items,
    count: data?.count,
    mutate: mutate,
    isLoading,
    isError: error,
  };
};

export default getAllOrders;
