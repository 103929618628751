import { getAllLocations } from './getAllLocations';
import { getAllMunicipalitiesBex } from './getAllMunicipalitiesBex';
import { getAllPlaces } from './getAllPlaces';
import { getAllStreets } from './getAllStreets';
import deleteLocation from './deleteLocation';

export {
  getAllLocations,
  deleteLocation,
  getAllMunicipalitiesBex,
  getAllPlaces,
  getAllStreets,
};
