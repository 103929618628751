import Button from "../Button/Button";

type Props = {
  action?: () => void;
  children: React.ReactNode;
};

export default function TableAction({ action, children }: Props) {
  return <Button onClick={action}>{children}</Button>;
}
