import { useEffect, useState } from 'react';
import { Form, Input, Select, Typography } from 'antd';
import Button from '../../../components/Button/Button';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import createLocation from '../api/createLocation';
import updateLocation from '../api/updateLocation';
import { getAllClients } from '../../Clients/api';
import { locationInitialState } from '../Location';
import { useForm } from 'antd/es/form/Form';

const { Text } = Typography;

export default function LocationForm({
  isNew,
  setModal,
  locationFormHandlerData,
  locationData,
  municipalitiesBex,
  municipalitiesDexpress,
}: {
  isNew: any;
  setModal: any;
  locationFormHandlerData: any;
  locationData: any;
  municipalitiesBex: any;
  municipalitiesDexpress: any;
}) {
  const [form] = useForm();
  const { user } = useAuthContext();
  const { clients } = getAllClients(user);

  /* BEX */
  const [selectedMunicipalityBex, setSelectedMunicipalityBex] = useState(
    !isNew ? locationData?.address[0]?.municipality : null
  );
  const [selectedPlaceBex, setSelectedPlaceBex] = useState(
    !isNew ? locationData?.address[0]?.place : null
  );
  const [filteredPlacesBex, setFilteredPlacesBex] = useState([]);
  const [filteredStreetsBex, setFilteredStreetsBex] = useState([]);

  /* DEXPRESS */
  const [selectedMunicipalityDexpress, setSelectedMunicipalityDexpress] =
    useState(!isNew ? locationData?.address[1]?.municipality : null);
  const [selectedPlaceDexpress, setSelectedPlaceDexpress] = useState(
    !isNew ? locationData?.address[1]?.place : null
  );
  const [filteredPlacesDexpress, setFilteredPlacesDexpress] = useState([]);
  const [filteredStreetsDexpress, setFilteredStreetsDexpress] = useState([]);

  /* BEX */
  useEffect(() => {
    if (selectedMunicipalityBex) {
      axios
        .get(
          `${SERVER_URL}/place_bex/getByMunicipality/${selectedMunicipalityBex}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setFilteredPlacesBex(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedMunicipalityBex]);
  useEffect(() => {
    if (selectedPlaceBex) {
      axios
        .get(`${SERVER_URL}/street_bex/getByPlace/${selectedPlaceBex}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
          withCredentials: false,
        })
        .then((res) => setFilteredStreetsBex(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedPlaceBex]);

  /* DEXPRESS */
  useEffect(() => {
    if (selectedMunicipalityDexpress) {
      axios
        .get(
          `${SERVER_URL}/place_dexpress/getByMunicipality/${selectedMunicipalityDexpress}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setFilteredPlacesDexpress(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedMunicipalityDexpress]);
  useEffect(() => {
    if (selectedPlaceDexpress) {
      axios
        .get(
          `${SERVER_URL}/street_dexpress/getByPlace/${selectedPlaceDexpress}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setFilteredStreetsDexpress(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedPlaceDexpress]);

  const handleSubmit = (values: any) => {
    const modifiedValues = {
      ...values,
      address: [
        {
          courierService: 'BEX',
          municipality: values.bexMunicipality,
          place: values.bexPlace,
          street: values.bexStreet,
          addressNumber: values.bexAddressNumber,
          apartmentNumber: values.bexApartmentNumber,
        },
        {
          courierService: 'DEXPRESS',
          municipality: values.dexpressMunicipality,
          place: values.dexpressPlace,
          street: values.dexpressStreet,
          addressNumber: values.dexpressAddressNumber,
          apartmentNumber: values.dexpressApartmentNumber,
        },
      ],
    };

    isNew
      ? createLocation(locationFormHandlerData, modifiedValues, user)
      : updateLocation(
          locationFormHandlerData,
          modifiedValues,
          locationData.id
        );

    setModal({
      modalFor: '',
      locationData: locationInitialState,
      isNew: false,
    });
  };

  const initialValues = {
    client: locationData?.client._id,
    locationName: locationData?.locationName,
    address: locationData?.address,
    bexMunicipality: locationData?.address
      ? locationData?.address[0]?.municipality
      : undefined,
    bexPlace: locationData?.address
      ? locationData?.address[0]?.place
      : undefined,
    bexStreet: locationData?.address
      ? locationData?.address[0]?.street
      : undefined,
    bexAddressNumber: locationData?.address
      ? locationData?.address[0]?.addressNumber
      : undefined,
    bexApartmentNumber: locationData?.address
      ? locationData?.address[0]?.apartmentNumber
      : undefined,
    dexpressMunicipality: locationData?.address
      ? locationData?.address[1]?.municipality
      : undefined,
    dexpressPlace: locationData?.address
      ? locationData?.address[1]?.place
      : undefined,
    dexpressStreet: locationData?.address
      ? locationData?.address[1]?.street
      : undefined,
    dexpressAddressNumber: locationData?.address
      ? locationData?.address[1]?.addressNumber
      : undefined,
    dexpressApartmentNumber: locationData?.address
      ? locationData?.address[1]?.apartmentNumber
      : undefined,
    email: locationData?.email,
    phoneNumber: locationData?.phoneNumber,
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="flex flex-col"
      initialValues={initialValues}>
      {user?.role === 'superAdmin' && (
        <Form.Item
          name="client"
          rules={[
            { required: true, message: 'Molimo Vas unesite kompaniju!' },
          ]}>
          <Select placeholder="Klijent">
            {clients?.map((c: any) => {
              return (
                <Select.Option key={c._id} value={c._id}>
                  {c.clientName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="locationName"
        rules={[
          { required: true, message: 'Molimo Vas unesite naziv lokacije!' },
        ]}>
        <Input placeholder="Ime lokacije" />
      </Form.Item>
      <Text className="mb-4 font-bold">Adrese:</Text>
      <div className="flex gap-4">
        <div>
          <Text className="mb-2 block">BEX Adresa:</Text>
          <Form.Item
            name="bexMunicipality"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite opštinu!',
              },
            ]}>
            <Select
              onChange={(value) => setSelectedMunicipalityBex(value)}
              filterOption={true}
              placeholder="Opština">
              {municipalitiesBex?.map((m: any) => (
                <Select.Option key={m._id} value={String(m.MunicipalitiesId)}>
                  {m.MunicipalitiesName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bexPlace"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite mesto!',
              },
            ]}>
            <Select
              onChange={(value) => setSelectedPlaceBex(value)}
              placeholder="Mesto">
              {filteredPlacesBex?.map((p: any) => (
                <Select.Option key={p._id} value={String(p.PlaceId)}>
                  {p.PlaceName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bexStreet"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite ulicu!',
              },
            ]}>
            <Select placeholder="Ulica">
              {filteredStreetsBex?.map((s: any) => (
                <Select.Option key={s._id} value={String(s.StreetId)}>
                  {s.StreetName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="bexAddressNumber"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite broj adrese!',
              },
            ]}>
            <Input placeholder="Broj adrese" />
          </Form.Item>
          <Form.Item name="bexApartmentNumber">
            <Input placeholder="Broj ulaza/stana" />
          </Form.Item>
        </div>
        <div>
          <Text className="mb-2 block">DExpress Adresa:</Text>
          <Form.Item
            name="dexpressMunicipality"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite opštinu!',
              },
            ]}>
            <Select
              onChange={(value) => setSelectedMunicipalityDexpress(value)}
              placeholder="Opština">
              {municipalitiesDexpress?.map((m: any) => (
                <Select.Option key={m._id} value={String(m.Id)}>
                  {m.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dexpressPlace"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite mesto!',
              },
            ]}>
            <Select
              onChange={(value) => setSelectedPlaceDexpress(value)}
              placeholder="Mesto">
              {filteredPlacesDexpress?.map((p: any) => (
                <Select.Option key={p._id} value={String(p.Id)}>
                  {p.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dexpressStreet"
            rules={[
              {
                required: true,
                message: 'Molimo Vas odaberite ulicu!',
              },
            ]}>
            <Select placeholder="Ulica">
              {filteredStreetsDexpress?.map((s: any) => (
                <Select.Option key={s._id} value={String(s.Id)}>
                  {s.Name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dexpressAddressNumber"
            rules={[
              {
                required: true,
                message: 'Molimo Vas unesite broj adrese!',
              },
            ]}>
            <Input placeholder="Broj adrese" />
          </Form.Item>
          <Form.Item name="dexpressApartmentNumber">
            <Input placeholder="Broj ulaza/stana" />
          </Form.Item>
        </div>
      </div>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Molimo Vas unesite e-mail adresu!' },
        ]}>
        <Input type="email" placeholder="E-mail adresa" />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[
          { required: true, message: 'Molimo Vas unesite broj telefona!' },
        ]}>
        <Input placeholder="Broj telefona" />
      </Form.Item>
      <Button htmlType="submit" stretch style="primary">
        {isNew ? 'Kreiraj' : 'Izmeni'} lokaciju
      </Button>
    </Form>
  );
}
