import { useRef, useState } from 'react';
import { Input, InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { FaEdit, FaTrash, FaSearch, FaPlus } from 'react-icons/fa';
import { getAllUsers, deleteUser } from './api/index';
import EditUser from './components/EditUser';
import DeleteUser from './components/DeleteUser';
import { TableUser } from '../../types/User';
import { Location } from '../../types/Location';
import { Client } from '../../types/Client';
import { useAuthContext } from '../../hooks/useAuthContext';
import Button from '../../components/Button/Button';
import TableActions from '../../components/Table/TableActions';
import TableAction from '../../components/Table/TableAction';
import PageLayout from '../../components/layouts/PageLayout';
import Table from '../../components/Table/Table';
import { getAllClients } from '../Clients/api';

type DataIndex = keyof TableUser;

export type Modal = {
  isNew?: boolean;
  modalFor: string;
  userData: TableUser;
};
export const userInitialState = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  client: '',
  location: '',
  role: '',
};
export const modalInitialState: Modal = {
  isNew: false,
  modalFor: '',
  userData: userInitialState,
};

export default function Users() {
  const { user } = useAuthContext();
  const [offset, setOffset] = useState(0);
  const { users, count, mutate, isLoading, isError } = getAllUsers(
    user,
    offset
  );
  const { clients } = getAllClients(user);
  const [modal, setModal] = useState<Modal>(modalInitialState);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  type filterDropdownProps = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: () => void;
    clearFilters: any;
    close: any;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: filterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pretraga`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button style="primary" onClick={() => handleSearch(confirm)}>
              Pretraži
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(confirm);
              }}>
              Resetuj
            </Button>
          </div>
          <Button
            style="text"
            onClick={() => {
              close();
            }}>
            Zatvori
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FaSearch style={{ color: filtered ? '#1D4ED8' : undefined }} />
    ),
    onFilter: (value: string, record: TableUser) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filteredRoles = users
    ?.map((u: TableUser) => u.role)
    ?.filter((x: TableUser, i: number, a: TableUser[]) => a.indexOf(x) === i)
    ?.map((r: string) => {
      if (r === 'superAdmin')
        return { text: 'Super admin', value: 'superAdmin' };
      if (r === 'clientAdmin') return { text: 'Admin', value: 'clientAdmin' };
      if (r === 'locationAdmin')
        return { text: 'Admin lokacije', value: 'locationAdmin' };

      return { text: r, value: r };
    });
  const filteredLocations = users
    ?.map((u: TableUser) => u.location)
    ?.filter(Boolean)
    ?.filter(
      (x: TableUser, i: number, a: TableUser[]) =>
        a.findIndex((o: TableUser) => o._id === x._id) === i
    )
    ?.map((l: Location) => {
      return l.locationName;
    });

  const filteredClients = users
    ?.map((u: TableUser) => u.client)
    ?.filter(Boolean)
    ?.filter(
      (x: TableUser, i: number, a: TableUser[]) =>
        a.findIndex((o: TableUser) => o._id === x._id) === i
    )
    ?.map((c: Client) => {
      return c.clientName;
    });

  const columns = [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: 'Ime',
      sorter: {
        compare: (a: TableUser, b: TableUser) =>
          a.firstName?.localeCompare(b.firstName),
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('firstName'),
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: 'Prezime',
      sorter: {
        compare: (a: TableUser, b: TableUser) =>
          a.lastName?.localeCompare(b.lastName),
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('lastName'),
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'E-mail',
      ...getColumnSearchProps('email'),
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Uloga',
      render: (role: string) => (
        <>
          {role === 'superAdmin'
            ? 'Super admin'
            : role === 'clientAdmin'
            ? 'Admin'
            : role === 'locationAdmin'
            ? 'Admin lokacije'
            : role}
        </>
      ),
      filters: filteredRoles?.map((r: any) => ({
        text: r.text,
        value: r.value,
      })),
      onFilter: (value: string, record: any) => record?.role?.startsWith(value),
    },
    {
      key: 'location',
      title: 'Lokacija',
      render: (record: TableUser) => (
        <>
          {record?.role === 'locationAdmin' ? (
            record?.location?.locationName
          ) : (
            <div className="text-center text-xs italic text-gray-300">-</div>
          )}
        </>
      ),
      filters: filteredLocations?.map((l: Location) => ({
        text: l,
        value: l,
      })),
      onFilter: (value: string, record: TableUser) =>
        record?.location?.locationName?.startsWith(value),
    },
    {
      key: 'actions',
      title: 'Alati',
      render: (record: TableUser) => (
        <TableActions>
          <TableAction
            action={() => setModal({ modalFor: 'edit', userData: record })}>
            <FaEdit title="Izmena korisnika" />
          </TableAction>
          {user?.email !== record?.email && (
            <TableAction
              action={() => setModal({ modalFor: 'delete', userData: record })}>
              <FaTrash title="Brisanje korisnika" />
            </TableAction>
          )}
        </TableActions>
      ),
    },
  ];

  if (user?.role === 'superAdmin')
    columns.unshift({
      key: 'client',
      title: 'Klijent',
      sorter: {
        compare: (a: TableUser, b: TableUser) =>
          a.client?.clientName?.localeCompare(b.client?.clientName),
      },
      sortDirections: ['descend', 'ascend'],
      filters: filteredClients?.map((c: Client) => ({
        text: c,
        value: c,
      })),
      onFilter: (value: string, record: TableUser) =>
        record?.client?.clientName?.startsWith(value),
      render: (record: TableUser) => (
        <>
          {record?.role !== 'superAdmin' ? (
            record?.client?.clientName
          ) : (
            <div className="text-center text-xs italic text-gray-300">
              (superAdmin)
            </div>
          )}
        </>
      ),
    });

  const tools = [
    {
      key: '1',
      children: (
        <span className="flex items-center justify-between gap-2">
          <FaPlus /> Kreiraj korisnika
        </span>
      ),
      toolFunction: () =>
        setModal({ modalFor: 'edit', userData: userInitialState, isNew: true }),
      style: 'primary' as 'primary',
    },
  ];

  return (
    <PageLayout title="Korisnici">
      {isError ? (
        <span>Desila se greška pri učitavanju podataka. Pokušajte ponovo.</span>
      ) : (
        <Table
          columns={columns}
          data={users}
          tools={tools}
          loading={isLoading}
          setOffset={setOffset}
          count={count}
        />
      )}
      <EditUser
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        userFormHandlerData={{ users, mutate, user }}
        clients={clients}
      />
      <DeleteUser
        modal={modal}
        setModal={setModal}
        modalInitialState={modalInitialState}
        deleteHandler={() => {
          deleteUser(users, mutate, modal.userData, user);
          setModal(modalInitialState);
        }}
      />
    </PageLayout>
  );
}
