import { Link } from 'react-router-dom';

type Props = {
  small?: boolean;
  width?: string;
  link?: boolean;
};

export default function Logo({
  small = false,
  width = '140px',
  link = false,
}: Props) {
  return (
    <>
      {link ? (
        <Link to="/" className="flex justify-center">
          <img
            style={{ width: width }}
            src={small ? 'images/5th_logo-square.svg' : 'images/5th_logo.svg'}
            alt="5th Element"
          />
        </Link>
      ) : (
        <img
          style={{ width: width }}
          src={small ? 'images/5th_logo-square.svg' : 'images/5th_logo.svg'}
          alt="5th Element"
        />
      )}
    </>
  );
}
