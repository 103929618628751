import axios from 'axios';
import { User } from '../../../types/User';
import { SERVER_URL } from '../../../config';

const createUserHandler = async (data: User, user: any) => {
  try {
    await axios.post(`${SERVER_URL}/users`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    console.log('error: ', error);
    // throw new Error('Neuspešno dodavanje korisnika.');
  }
};

const createUser = async (userFormHandlerData: any, userData: User) => {
  try {
    await userFormHandlerData.mutate(
      createUserHandler(userData, userFormHandlerData.user),
      {
        optimisticData: [...userFormHandlerData.users, userData],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Create User Error: ', error);
    // throw new Error('Neuspešno dodavanje korisnika.');
  }
};

export default createUser;
