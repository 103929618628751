import axios from 'axios';
import { SERVER_URL } from '../../../config';

const getUserById = async (userId: string, authUser: any) => {
  try {
    const res = await axios
      .get(`${SERVER_URL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${authUser?.token}` },
        withCredentials: false,
      })
      .then((res) => res.data);

    return res;
  } catch (error) {
    console.log('error on getUserById: ', error);
    throw new Error('Serverska greška. Molimo pokušajte kasnije.');
  }
};

export default getUserById;
