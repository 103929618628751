import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { ViewModalOrder } from '../../../types/Order';

const setOrderLocationHandler = async (
  data: ViewModalOrder,
  id: string,
  user: any
) => {
  try {
    await axios.put(`${SERVER_URL}/orders/${id}`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešna izmena lokacije.');
  }
};

const setOrderLocation = async (
  orderFormHandlerData: any,
  orderData: ViewModalOrder,
  id: string
) => {
  try {
    await orderFormHandlerData.mutate(
      setOrderLocationHandler(orderData, id, orderFormHandlerData.user),
      {
        optimisticData: [...orderFormHandlerData.orders],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Set Location Error: ', error);
    throw new Error('Neuspešna postavka lokacije.');
  }
};

export default setOrderLocation;
