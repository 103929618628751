import { Dispatch, SetStateAction } from 'react';
import { Typography } from 'antd';
import { Modal as ModalProps } from '../Orders';
import Modal from '../../../components/Modal/Modal';
import getFormattedDate from '../../../utils/getFormattedDate';
import { useAuthContext } from '../../../hooks/useAuthContext';

const { Paragraph, Text, Title } = Typography;

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
};

export default function ViewOrder({
  modal,
  setModal,
  modalInitialState,
}: Props) {
  const { user } = useAuthContext();
  const formattedDate = getFormattedDate(modal?.orderData?.createdAt);

  return (
    <Modal
      open={modal?.modalFor === 'view'}
      title="Pregled porudžbine"
      onCancel={() => setModal(modalInitialState)}>
      {user?.role === 'superAdmin' && (
        <Paragraph>
          <Text className="font-bold">Klijent: </Text>
          <Text>{modal?.orderData?.client?.clientName}</Text>
        </Paragraph>
      )}
      <Paragraph>
        <Text className="font-bold">Kreirano </Text>
        <Text>{formattedDate}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Kurirska služba: </Text>
        <Text>{modal?.orderData?.courierService}</Text>
      </Paragraph>
      <hr className="mb-2" />
      <Paragraph>
        <Title level={4}>Poručilac</Title>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Ime i prezime: </Text>
        <Text>{modal?.orderData?.recipientContact}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Broj telefona: </Text>
        <Text>{modal?.orderData?.recipientPhone}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">E-mail adresa: </Text>
        <Text>{modal?.orderData?.recipientEmail}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Grad: </Text>
        <Text>{modal?.orderData?.recipientCity}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Adresa: </Text>
        <Text>{`${modal?.orderData?.recipientAddress} ${
          modal?.orderData?.recipientAddressNumber
        }${
          modal?.orderData?.recipientApartmentNumber
            ? '/' + modal.orderData.recipientApartmentNumber
            : ''
        }`}</Text>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Napomena poručioca: </Text>
        <Text>{modal?.orderData?.recipientComment}</Text>
      </Paragraph>
      {/* <hr className="mb-3" />
      <Paragraph>
        <Title level={4}>Pošiljalac</Title>
      </Paragraph>
      <Paragraph>
        <Text className="font-bold">Napomena pošiljaoca: </Text>
        <Text>{modal?.orderData?.senderComment}</Text>
      </Paragraph> */}
    </Modal>
  );
}
