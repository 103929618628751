import { FunctionComponent, useContext } from 'react';
import { ConfigProvider } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import Clients from './pages/Clients/Clients';
import Orders from './pages/Orders/Orders';
import Shipments from './pages/Shipments/Shipments';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import NewPassword from './pages/NewPassword/NewPassword';
import Location from './pages/Location/Location';
import Root from './components/layouts/Root';
import NoMatch from './pages/NoMatch';

export function withCondition(
  Component: FunctionComponent,
  condition: boolean,
  redirectTo: string
) {
  return function InnerComponent(props: any) {
    return condition ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}
export const withToken = (Component: React.FunctionComponent) => {
  const tokenInStorage = JSON.parse(sessionStorage.getItem('user') || '{}');
  return withCondition(
    Component,
    Boolean(
      tokenInStorage.token &&
        useContext(AuthContext).user?.token === tokenInStorage.token
    ),
    '/login'
  );
};
export const withTokenAndRole = (
  Component: React.FunctionComponent,
  allowedRoles: string[]
) => {
  const userInStorage = JSON.parse(sessionStorage.getItem('user') || '{}');
  const { user } = useContext(AuthContext);
  return withCondition(
    Component,
    Boolean(
      userInStorage.token &&
        user?.token === userInStorage.token &&
        allowedRoles?.includes(user?.role)
    ),
    '/login'
  );
};
export const withoutToken = (Component: React.FunctionComponent) =>
  withCondition(Component, !Boolean(useContext(AuthContext).user?.token), '/');

export default function App() {
  const HomeRoute = withToken(Home);
  const UsersRoute = withTokenAndRole(Users, ['superAdmin', 'clientAdmin']);
  const ClientsRoute = withTokenAndRole(Clients, ['superAdmin']);
  const LocationRoute = withTokenAndRole(Location, [
    'superAdmin',
    'clientAdmin',
  ]);
  const OrdersRoute = withToken(Orders);
  const ShipmentsRoute = withToken(Shipments);

  const LoginRoute = withoutToken(Login);
  const RegisterRoute = withoutToken(Register);
  const ForgotPasswordRoute = withoutToken(ForgotPassword);
  const NewPasswordRoute = withoutToken(NewPassword);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1D4ED8',
          colorPrimaryHover: '#3B82F6',
          colorLink: '#1D4ED8',
          colorLinkHover: '#3B82F6',
          colorBgLayout: '#FFFFFF',
          fontFamily: 'Inter',
          colorBorderSecondary: '#cbd5e1',
          borderRadius: 8,
          controlItemBgActive: '#eff6ff',
        },
        components: {
          Table: {
            cellPaddingBlock: 8,
          },
        },
      }}>
      <Routes>
        <Route path="/" element={<Root />}>
          <Route index element={<HomeRoute />} />
          <Route path="users" element={<UsersRoute />} />
          <Route path="clients" element={<ClientsRoute />} />
          <Route path="location" element={<LocationRoute />} />
          <Route path="orders" element={<OrdersRoute />} />
          <Route path="shipments" element={<ShipmentsRoute />} />
        </Route>
        <Route path="login" element={<LoginRoute />} />
        <Route path="register" element={<RegisterRoute />} />
        <Route path="forgot-password" element={<ForgotPasswordRoute />} />
        <Route path="new-password" element={<NewPasswordRoute />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ConfigProvider>
  );
}
