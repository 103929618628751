import { Modal as AntModal } from 'antd';

type Props = {
  open: boolean;
  title?: string;
  onCancel: () => void;
  onOk?: () => void;
  showActions?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  modifiedWidth?: number;
  children: React.ReactNode;
};

const okButtonProps = {
  className: 'bg-blue-700 text-white hover:bg-blue-600 font-semibold',
};
const cancelButtonProps = {
  className:
    'border border-blue-700 bg-transparent text-blue-700 hover:border-blue-600 hover:bg-blue-50 hover:text-blue-500',
};

export default function Modal({
  open,
  title,
  onCancel,
  onOk,
  showActions = false,
  confirmButtonText = 'Potvrdi',
  cancelButtonText = 'Otkaži',
  modifiedWidth,
  children,
}: Props) {
  return (
    <>
      {showActions ? (
        <AntModal
          width={modifiedWidth}
          destroyOnClose
          closable
          centered
          open={open}
          title={title}
          onCancel={onCancel}
          onOk={onOk}
          okText={confirmButtonText}
          okButtonProps={okButtonProps}
          cancelButtonProps={cancelButtonProps}
          cancelText={cancelButtonText}>
          <div className="mt-8">{children}</div>
        </AntModal>
      ) : (
        <AntModal
          width={modifiedWidth}
          destroyOnClose
          closable
          centered
          open={open}
          title={title}
          onCancel={onCancel}
          footer={null}>
          <div className="mt-8">{children}</div>
        </AntModal>
      )}
    </>
  );
}
