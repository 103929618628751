export type Props = {
  label: string;
  value: number;
  unit?: string;
};

export default function Statistic({ label, value, unit }: Props) {
  return (
    <div className="flex min-w-[150px] flex-auto select-none flex-col rounded-lg bg-blue-50 p-4">
      <p className="font-medium text-blue-700">{label}</p>
      <p className="mt-1 flex flex-auto items-end text-3xl font-medium text-slate-700">
        {value.toLocaleString('sr-RS')} {unit}
      </p>
    </div>
  );
}
