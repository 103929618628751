import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Input, InputNumber, Select, Switch, Typography } from 'antd';
import { SERVER_URL } from '../../../config';
import { Modal as ModalProps, orderInitialState } from '../Orders';
import Button from '../../../components/Button/Button';
import createOrder from '../api/createOrder';
import updateOrder from '../api/updateOrder';
import {
  payTypeOptions,
  recipientTypeOptions,
  preNotificationOptions,
  shipmentCategoryOptions,
  shipmentTypeOptions,
} from '../formOptions';
import courierServices from '../../../data/courierServices';
import { getAllMunicipalitiesBex } from '../../Location/api';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Municipality } from '../../../types/Location';

const { Text, Title } = Typography;

type OrderFormProps = {
  isNew: boolean;
  orderData: any;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  orderFormHandlerData: any;
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export default function OrderForm({
  isNew = false,
  orderData,
  setModal,
  orderFormHandlerData,
}: OrderFormProps) {
  const { user } = useAuthContext();
  const { municipalitiesBex } = getAllMunicipalitiesBex(user);

  const [payToSenderViaAccount, setPayToSenderViaAccount] = useState(
    orderData?.payToSenderViaAccount || false
  );
  const [recipientType, setRecipientType] = useState(
    orderData?.recipientType || 1
  );

  const [selectedMunicipality, setSelectedMunicipality] = useState(
    orderData?.recipientMunicipality || ''
  );
  const [selectedPlace, setSelectedPlace] = useState(
    orderData?.recipientCity || ''
  );
  const [selectedMunicipalityId, setSelectedMunicipalityId] = useState();
  const [selectedPlaceId, setSelectedPlaceId] = useState();
  console.log('selectedPlaceId: ', selectedPlaceId);

  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [filteredStreets, setFilteredStreets] = useState([]);
  console.log('filteredStreets: ', filteredStreets);

  /* GET MUNICIPALITY ID FROM SELECTED MUNICIPALITY */
  useEffect(() => {
    if (selectedMunicipality) {
      axios
        .get(
          `${SERVER_URL}/municipality_bex/getDataByMunicipalityName/${selectedMunicipality}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setSelectedMunicipalityId(res?.data?.MunicipalitiesId))
        .catch((error: any) =>
          console.log('Error when fetching municipality id: ', error)
        );
    }
  }, [selectedMunicipality]);

  /* GET PLACE ID FROM SELECTED PLACE */
  useEffect(() => {
    if (selectedMunicipalityId && selectedPlace) {
      console.log('selectedMunicipalityId: ', selectedMunicipalityId);
      console.log('selectedPlace: ', selectedPlace);
      axios
        .get(
          `${SERVER_URL}/place_bex/getDataByPlaceName/${selectedMunicipalityId}/${selectedPlace}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setSelectedPlaceId(res?.data?.PlaceId))
        .catch((error: any) =>
          console.log('Error when fetching place: ', error)
        );
    }
  }, [selectedMunicipalityId, selectedPlace]);

  useEffect(() => {
    if (selectedPlaceId) {
      axios
        .get(`${SERVER_URL}/street_bex/getByPlace/${selectedPlaceId}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
          withCredentials: false,
        })
        .then((res) => setFilteredStreets(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedPlaceId]);

  useEffect(() => {
    if (selectedMunicipalityId) {
      axios
        .get(
          `${SERVER_URL}/place_bex/getByMunicipality/${selectedMunicipalityId}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
            withCredentials: false,
          }
        )
        .then((res) => setFilteredPlaces(res.data))
        .catch((error: any) =>
          console.log('Error when fetching places by municipality: ', error)
        );
    }
  }, [selectedMunicipalityId]);

  const handleSubmit = (values: any) => {
    isNew
      ? createOrder(orderFormHandlerData, {
          ...values,
          clientId: orderFormHandlerData?.user?.client?.clientId,
        })
      : updateOrder(orderFormHandlerData, values, orderData?._id);

    setModal({
      modalFor: '',
      orderData: orderInitialState,
      isNew: false,
    });
  };

  const initialValues = {
    shipmentType: !isNew ? orderData?.shipmentType : 1,
    commentPublic: !isNew ? orderData?.commentPublic : '',
    commentPrivate: !isNew ? orderData?.commentPrivate : '',
    totalPackages: !isNew ? orderData?.totalPackages : 1,
    shipmentCategory: !isNew ? orderData?.shipmentCategory : 1,
    payType: !isNew ? orderData?.payType : 1,
    payToSender: !isNew ? orderData?.payToSender : 0,
    payToSenderViaAccount: !isNew ? orderData?.payToSenderViaAccount : false,
    sendersAccountNumber: !isNew ? orderData?.sendersAccountNumber : '',
    senderPreNotification: !isNew ? orderData?.senderPreNotification : 0,
    senderComment: !isNew ? orderData?.senderComment : '',
    recipientType: !isNew ? orderData?.recipientType : 1,
    recipientFirstName: !isNew ? orderData?.recipientFirstName : '',
    recipientLastName: !isNew ? orderData?.recipientLastName : '',
    recipientMunicipality: !isNew
      ? orderData?.recipientMunicipality?.toUpperCase()
      : '',
    recipientCity: !isNew ? orderData?.recipientCity?.toUpperCase() : '',
    recipientZipCode: !isNew ? orderData?.recipientZipCode : null,
    recipientAddress: !isNew ? orderData?.recipientAddress?.toUpperCase() : '',
    recipientAddressNumber: !isNew ? orderData?.recipientAddressNumber : null,
    recipientApartmentNumber: !isNew ? orderData?.recipientApartmentNumber : '',
    recipientPhone: !isNew ? orderData?.recipientPhone : '',
    recipientContact: !isNew ? orderData?.recipientContact : '',
    recipientPreNotification: !isNew ? orderData?.recipientPreNotification : 0,
    recipientEmail: !isNew ? orderData?.recipientEmail : '',
    recipientPib: !isNew ? orderData?.recipientPib : '',
    recipientComment: !isNew ? orderData?.recipientComment : '',
    courierService: !isNew ? orderData?.courierService : '',
    isAddressFound: !isNew ? orderData?.isAddressFound : false,
  };

  return (
    <Form
      onFinish={handleSubmit}
      className="flex flex-col"
      initialValues={initialValues}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Text>Vrsta isporuke:</Text>
          <Form.Item name="shipmentType">
            <Select
              placeholder="Vrsta isporuke"
              options={shipmentTypeOptions}
            />
          </Form.Item>
        </div>
        <div>
          <Text>Kategorija isporuke:</Text>
          <Form.Item name="shipmentCategory">
            <Select
              placeholder="Kategorija isporuke"
              options={shipmentCategoryOptions}
            />
          </Form.Item>
        </div>
      </div>
      <hr className="mb-4" />
      <Text>Broj paketa:</Text>
      <Form.Item name="totalPackages">
        <InputNumber type="number" min={1} max={9999} step={1} />
      </Form.Item>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <Text>Način plaćanja:</Text>
          <Form.Item name="payType">
            <Select placeholder="Način plaćanja" options={payTypeOptions} />
          </Form.Item>
        </div>
        <div>
          <Text>Iznos za uplatu:</Text>
          <Form.Item name="payToSender">
            <InputNumber type="number" min={0} max={9999999} step={0.01} />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <Text>Plaćanje preko računa</Text>
          <Form.Item name="payToSenderViaAccount" valuePropName="checked">
            <Switch
              onChange={(checked) => setPayToSenderViaAccount(checked)}
              className="switch-modifier"
              checkedChildren="DA"
              unCheckedChildren="NE"
            />
          </Form.Item>
        </div>
        {payToSenderViaAccount && (
          <div className="col-span-2">
            <Text>Broj računa:</Text>
            <Form.Item name="sendersAccountNumber">
              <Input placeholder="Broj računa" />
            </Form.Item>
          </div>
        )}
      </div>
      <hr className="mb-4" />
      <Title level={4}>Pošiljalac</Title>
      <Text>Obaveštenja pošiljaoca pre isporuke:</Text>
      <Form.Item name="senderPreNotification">
        <Select
          placeholder="Obaveštenje pošiljaoca"
          options={preNotificationOptions}
        />
      </Form.Item>
      <Text>Komentar pošiljaoca:</Text>
      <Form.Item name="senderComment">
        <Input.TextArea
          placeholder="Komentar pošiljaoca"
          rows={4}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Form.Item>
      <hr className="mb-4" />
      <Title level={4}>Poručilac</Title>
      <Text>Vrsta poručioca:</Text>
      <Form.Item name="recipientType">
        <Select
          onChange={(value) => setRecipientType(value)}
          placeholder="Vrsta poručioca"
          options={recipientTypeOptions}
        />
      </Form.Item>
      {recipientType === 1 && (
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Text>Ime:</Text>
            <Form.Item name="recipientFirstName">
              <Input placeholder="Ime poručioca" />
            </Form.Item>
          </div>
          <div>
            <Text>Prezime:</Text>
            <Form.Item name="recipientLastName">
              <Input placeholder="Prezime poručioca" />
            </Form.Item>
          </div>
        </div>
      )}
      {recipientType === 2 && (
        <>
          <Text>PIB:</Text>
          <Form.Item name="recipientPib">
            <Input placeholder="PIB" />
          </Form.Item>
        </>
      )}
      <Title level={5}>Adresa:</Title>
      {!isNew && !orderData?.isAddressFound && (
        <div className="mb-4 rounded-lg border border-red-600 bg-red-100 px-4 py-2 text-red-600">
          Uneta adresa nije prepoznata. Proverite da li su{' '}
          <strong>opština, mesto i ulica</strong> dobro uneti. Izmenite podatke
          kako bi adresa bila spremna za kurirsku službu. Kada sačuvate izmene,
          proverite da li je adresa postala validna.
        </div>
      )}
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-2">
          <Text>Opština:</Text>
          <Form.Item name="recipientMunicipality">
            {municipalitiesBex?.length > 0 ? (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value: any) => setSelectedMunicipality(value)}
                options={municipalitiesBex?.map(
                  (municipality: Municipality) => ({
                    label: municipality.MunicipalitiesName,
                    value: municipality.MunicipalitiesName,
                  })
                )}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        </div>
        <div className="col-span-2">
          <Text>Mesto:</Text>
          <Form.Item name="recipientCity">
            {filteredPlaces?.length > 0 ? (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value: any) => setSelectedPlace(value)}
                options={filteredPlaces?.map((place: any) => ({
                  label: place.PlaceName,
                  value: place.PlaceName,
                }))}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        </div>
        <div>
          <Text>PK:</Text>
          <Form.Item name="recipientZipCode" className="w-full">
            <InputNumber
              className="w-full"
              placeholder="Poštanski kod"
              min={11000}
              max={99999}
              step={1}
            />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-4">
        <div className="col-span-3">
          <Text>Ulica:</Text>
          <Form.Item name="recipientAddress">
            {filteredStreets?.length > 0 ? (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                options={filteredStreets?.map((street: any) => ({
                  label: street.StreetName,
                  value: street.StreetName,
                }))}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        </div>
        <div className="col-span-2">
          <Text>Broj adrese:</Text>
          <Form.Item name="recipientAddressNumber">
            <InputNumber
              className="w-full"
              placeholder="Broj adrese"
              min={1}
              max={99999}
              step={1}
            />
          </Form.Item>
        </div>
        <div className="col-span-2">
          <Text>Stan/ulaz:</Text>
          <Form.Item name="recipientApartmentNumber">
            <Input placeholder="Broj stana/ulaza" />
          </Form.Item>
        </div>
      </div>
      <Text>Kontakt osoba:</Text>
      <Form.Item name="recipientContact">
        <Input placeholder="Kontakt osoba" />
      </Form.Item>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Text>Broj telefona:</Text>
          <Form.Item name="recipientPhone">
            <Input placeholder="Broj telefona" />
          </Form.Item>
        </div>
        <div>
          <Text>E-mail adresa:</Text>
          <Form.Item name="recipientEmail">
            <Input type="email" placeholder="E-mail adresa" />
          </Form.Item>
        </div>
      </div>
      <Text>Komentar poručioca:</Text>
      <Form.Item name="recipientComment">
        <Input.TextArea
          placeholder="Komentar poručioca"
          rows={4}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Form.Item>
      <Text>Kurirska služba:</Text>
      <Form.Item name="courierService">
        <Select placeholder="Kurirska služba" options={courierServices} />
      </Form.Item>
      <Text>Obaveštenja poručioca pre isporuke:</Text>
      <Form.Item name="recipientPreNotification">
        <Select
          placeholder="Obaveštenje poručioca"
          options={preNotificationOptions}
        />
      </Form.Item>
      <hr className="mb-4" />
      <Text>Interna napomena:</Text>
      <Form.Item name="commentPrivate">
        <Input.TextArea
          placeholder="Interna napomena"
          rows={4}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Form.Item>
      <Text>Napomena:</Text>
      <Form.Item name="commentPublic">
        <Input.TextArea
          placeholder="Napomena"
          rows={4}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Form.Item>
      <Button
        htmlType="submit"
        stretch
        style="primary" /*disabled={isLoading}*/
      >
        {isNew ? 'Kreiraj porudžbinu' : 'Izmeni porudžbinu'}
      </Button>
      {/* {error && <div>{error}</div>} */}
    </Form>
  );
}
