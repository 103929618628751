import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, Input, Select, Switch, Typography, message } from 'antd';
import Modal from '../Modal/Modal';
import courierServices from '../../data/courierServices';
import { useAuthContext } from '../../hooks/useAuthContext';
import Button from '../Button/Button';
import updateSelectedCourierServices from '../../pages/Clients/api/updateSelectedCourierServices';
import getClientById from '../../pages/Clients/api/getClientById';
import getFormattedCourierServiceForSelect from '../../utils/getFormattedCourierServiceForSelect';
import updateClientCourierServices from '../../pages/Clients/api/updateClientCourierServices';
import { CourierPricing } from '../../types/Client';
import { FaTrash } from 'react-icons/fa';
import { CourierService } from '../../types/CourierService';

const { Paragraph } = Typography;

type Props = {
  showSettingsModal: boolean;
  setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
};

type FormattedCourierService = {
  label: string;
  value: string;
};

export default function UserSettingsModal({
  showSettingsModal,
  setShowSettingsModal,
}: Props) {
  const { user } = useAuthContext();
  const [formattedCourierServices, setFormattedCourierServices] = useState<
    FormattedCourierService[]
  >([]);
  const [clientSelectedCourierServices, setClientSelectedCourierServices] =
    useState<string[]>([]);
  const [clientCourierPricingDetails, setClientCourierPricingDetails] =
    useState<CourierPricing[]>([]);
  const [refetchData, setRefetchData] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const { selectedCourierServices, courierPricingDetails } =
          await getClientById(user);
        if (selectedCourierServices && selectedCourierServices?.length > 0) {
          setClientSelectedCourierServices(selectedCourierServices);
        } else {
          setClientSelectedCourierServices(
            courierServices.map((cs: CourierService) => cs.value)
          );
        }
        if (courierPricingDetails) {
          setClientCourierPricingDetails(courierPricingDetails);
        }
      } catch (error) {
        console.error(
          'Error fetching client selected courier services:',
          error
        );
      }
    };

    if (user) {
      fetchClientData();
    }
  }, [user, refetchData]);

  useEffect(() => {
    setFormattedCourierServices(
      getFormattedCourierServiceForSelect(clientSelectedCourierServices)
    );
  }, [clientSelectedCourierServices]);

  const courierServicesInitialValues = {
    selectedCourierServices: formattedCourierServices,
  };

  const handleSubmit = (values: any, courierService: string) => {
    const objectToSend = {
      courierServiceName: courierService,
      isActive: values.isActive,
      packagePrices: values.packagePrices,
    };

    const existingCourierIndex = clientCourierPricingDetails.findIndex(
      (courier: any) =>
        courier.courierServiceName === objectToSend.courierServiceName
    );

    if (existingCourierIndex !== -1) {
      // If the courier service already exists, update its properties
      const updatedCourierServices = [...clientCourierPricingDetails];
      updatedCourierServices[existingCourierIndex] = {
        ...updatedCourierServices[existingCourierIndex],
        ...objectToSend,
      };
      setClientCourierPricingDetails(updatedCourierServices);
      updateClientCourierServices(updatedCourierServices, user);
    } else {
      // If the courier service doesn't exist, add it to the array
      setClientCourierPricingDetails([
        ...clientCourierPricingDetails,
        objectToSend,
      ]);
      updateClientCourierServices(
        [...clientCourierPricingDetails, objectToSend],
        user
      );
    }

    messageApi.open({
      type: 'success',
      content: 'Uspešno sačuvano!',
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        modifiedWidth={700}
        open={showSettingsModal}
        title="Podešavanja"
        onCancel={() => setShowSettingsModal(false)}>
        {formattedCourierServices?.length > 0 && (
          <Form
            className="mb-4 flex flex-col"
            initialValues={courierServicesInitialValues}
            onFinish={(values: any) => {
              updateSelectedCourierServices(values, user);
              setRefetchData((prev) => !prev);
            }}>
            <Paragraph>Aktivne kurirske službe</Paragraph>
            <div className="flex gap-4">
              <Form.Item name="selectedCourierServices" className="mb-0 grow">
                <Select
                  mode="multiple"
                  showSearch={false}
                  options={courierServices}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                style="primary" /*disabled={isLoading}*/
              >
                Sačuvaj
              </Button>
            </div>
            <hr className="mt-4" />
          </Form>
        )}

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {formattedCourierServices?.map((cs: any) => (
            <div className="rounded-lg border p-4" key={cs.value}>
              <Form
                className="flex flex-col"
                onFinish={(values) => handleSubmit(values, cs.value)}
                initialValues={clientCourierPricingDetails?.find(
                  (courierDetails: any) =>
                    courierDetails?.courierServiceName === cs?.value
                )}>
                <div className="mb-3 flex items-start justify-between gap-4">
                  <div>
                    <span>{cs.label}</span>
                    <Form.Item
                      name="isActive"
                      valuePropName="checked"
                      className="m-0">
                      <Switch
                        className="switch-modifier"
                        checkedChildren={'Aktivno'}
                        unCheckedChildren={'Neaktivno'}
                      />
                    </Form.Item>
                  </div>
                  <Button style="primary" htmlType="submit">
                    Sačuvaj {cs.label}
                  </Button>
                </div>
                <Form.List name="packagePrices">
                  {(fields, { add, remove }) => {
                    return (
                      <div className="grid max-h-80 grid-cols-1 gap-1 overflow-y-auto">
                        {fields.map((field, index) => (
                          <div
                            key={field.key}
                            className="mb-2 flex gap-2 rounded-lg bg-slate-200 p-4">
                            <Form.Item name={[index, 'size']} className="m-0">
                              <Input placeholder="Naziv" />
                            </Form.Item>
                            <Form.Item
                              name={[index, 'price']}
                              className="m-0 min-w-[64px] flex-1">
                              <Input placeholder="Cena" />
                            </Form.Item>
                            {fields.length > 1 ? (
                              <Button
                                htmlType="button"
                                onClick={() => remove(field.name)}>
                                <FaTrash />
                              </Button>
                            ) : null}
                          </div>
                        ))}
                        <Form.Item className="m-0">
                          <Button
                            style="primary"
                            htmlType="button"
                            onClick={() => add()}>
                            Dodaj veličinu
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              </Form>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
