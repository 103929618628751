import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Select, Spin, Typography } from 'antd';
import { Modal as ModalProps } from '../Orders';
import Modal from '../../../components/Modal/Modal';
import getFormattedDate from '../../../utils/getFormattedDate';
import { useAuthContext } from '../../../hooks/useAuthContext';
import Button from '../../../components/Button/Button';
import { getAllLocations } from '../../Location/api';
import setOrderLocation from '../api/setOrderLocation';
import createShipmentFromOrder from '../api/createShipmentFromOrder';

const { Paragraph, Text, Title } = Typography;

type Props = {
  modal: ModalProps;
  modalInitialState: ModalProps;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  orderFormHandlerData: any;
  mutate: any;
};

export default function CreateShipment({
  modal,
  setModal,
  modalInitialState,
  orderFormHandlerData,
  mutate,
}: Props) {
  const { user } = useAuthContext();
  const { locations } = getAllLocations(user);
  const formattedDate = getFormattedDate(modal?.orderData?.createdAt);
  const [selectedLocation, setSelectedLocation] = useState(); // to handle current state
  const [loading, setLoading] = useState(false);

  const updateOrderLocationHandler = (value: any) => {
    setOrderLocation(
      orderFormHandlerData,
      { ...modal?.orderData, location: value },
      modal?.orderData?._id
    );

    setSelectedLocation(value);
  };

  // update order location for locationAdmin or if there is only 1 location
  useEffect(() => {
    if (user?.role === 'locationAdmin' && user?.location) {
      setOrderLocation(
        orderFormHandlerData,
        { ...modal?.orderData, location: user?.location?._id },
        modal?.orderData?._id
      );
    }
    if (locations?.length === 1) {
      setOrderLocation(
        orderFormHandlerData,
        { ...modal?.orderData, location: locations[0]?._id },
        modal?.orderData?._id
      );
    }
  }, [user, locations]);

  return (
    <>
      <Modal
        open={modal?.modalFor === 'createShipment'}
        title={loading ? 'Kreiranje pošiljke u toku' : 'Pregled porudžbine'}
        onCancel={() => setModal(modalInitialState)}>
        {loading ? (
          <Spin />
        ) : (
          <>
            {user?.role === 'superAdmin' && (
              <Paragraph>
                <Text className="font-bold">Klijent: </Text>
                <Text>{modal?.orderData?.client?.clientName}</Text>
              </Paragraph>
            )}
            <Paragraph>
              <Text className="font-bold">Kreirano </Text>
              <Text>{formattedDate}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Kurirska služba: </Text>
              <Text>{modal?.orderData?.courierService}</Text>
            </Paragraph>
            <hr className="mb-2" />
            <Paragraph>
              <Title level={4}>Poručilac</Title>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Ime i prezime: </Text>
              <Text>{modal?.orderData?.recipientContact}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Broj telefona: </Text>
              <Text>{modal?.orderData?.recipientPhone}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">E-mail adresa: </Text>
              <Text>{modal?.orderData?.recipientEmail}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Grad: </Text>
              <Text>{modal?.orderData?.recipientCity}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Adresa: </Text>
              <Text>{`${modal?.orderData?.recipientAddress} ${
                modal?.orderData?.recipientAddressNumber
              }${
                modal?.orderData?.recipientApartmentNumber
                  ? '/' + modal.orderData.recipientApartmentNumber
                  : ''
              }`}</Text>
            </Paragraph>
            <Paragraph>
              <Text className="font-bold">Napomena poručioca: </Text>
              <Text>{modal?.orderData?.recipientComment}</Text>
            </Paragraph>
            <hr className="mb-2" />
            {user?.role !== 'locationAdmin' && (
              <>
                <Select
                  placeholder="Lokacija za slanje"
                  className="mb-2 w-full"
                  disabled={locations?.length === 1}
                  value={
                    locations?.length === 1
                      ? locations[0]?.id
                      : modal?.orderData?.location || selectedLocation
                  }
                  onChange={(value) => updateOrderLocationHandler(value)}>
                  {locations?.map((l: any) => (
                    <Select.Option key={l.id} value={l.id}>
                      {l?.locationName}
                    </Select.Option>
                  ))}
                </Select>
                <hr className="mb-2" />
              </>
            )}
            <Button
              disabled={!selectedLocation && !modal?.orderData?.location}
              onClick={() =>
                createShipmentFromOrder(user, modal, setModal, setLoading).then(
                  mutate
                )
              }>
              Pošalji zahtev za pošiljku
            </Button>
          </>
        )}
      </Modal>
    </>
  );
}
