const getFormattedCourierServiceForSelect = (courierServices: string[]) => {
  const formattedCourierServices = courierServices?.map((courierService) => {
    return {
      label: courierService,
      value: courierService,
    };
  });

  return formattedCourierServices;
};

export default getFormattedCourierServiceForSelect;
