import TableTool, { Props as ToolProps } from './TableTool';

type Props = {
  toolsToShow?: ToolProps[];
};

export default function TableToolbar({ toolsToShow }: Props) {
  if (!toolsToShow || !Array.isArray(toolsToShow)) {
    return null;
  }

  return (
    <div className="mb-4 flex w-full min-w-max items-center justify-start gap-4 sm:justify-end">
      {toolsToShow.length > 0 &&
        toolsToShow.map(
          ({ key, children, toolFunction, style, modifier, hidden }) => (
            <TableTool
              key={key}
              toolFunction={toolFunction}
              style={style}
              modifier={modifier}
              hidden={hidden}>
              {children}
            </TableTool>
          )
        )}
    </div>
  );
}
