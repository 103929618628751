import { useState, useRef, useEffect } from 'react';
import { Checkbox, DatePicker, Input, InputRef, Select } from 'antd';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ViewModalOrder, TableOrder } from '../../types/Order';
import PageLayout from '../../components/layouts/PageLayout';
import Table from '../../components/Table/Table';
import Modal from '../../components/Modal/Modal';
import getAllOrders from './api/getAllOrders';
import ViewOrder from './components/ViewOrder';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Button from '../../components/Button/Button';
import { getAllClients } from '../Clients/api';
import { Client, SessionClient } from '../../types/Client';
import CreateShipment from './components/CreateShipment';
import ViewShipment from '../Shipments/components/ViewShipment';
import getFormattedCourierServiceForSelect from '../../utils/getFormattedCourierServiceForSelect';
import courierServices from '../../data/courierServices';
import updateOrder from './api/updateOrder';
import OrderChat from './components/OrderChat';
import { defaultColumns, defaultColumnsAdmin } from './columns';
import { adminOrdersTableFunctions } from '../../data/allowedRoles';
import { CourierService } from '../../types/CourierService';
import EditOrder from './components/EditOrder';

type DataIndex = keyof TableOrder;

export type Modal = {
  isNew?: boolean;
  modalFor: string;
  orderData: ViewModalOrder;
};
export type ShipmentModal = {
  modalFor: string;
  shipmentId: string;
};

export const orderInitialState = {
  _id: '',
  location: '',
  client: {
    clientId: '',
    email: '',
    clientName: '',
    phoneNumber: '',
    pib: '',
  },
  createdAt: '',
  courierService: '',
  recipientContact: '',
  recipientPhone: '',
  recipientEmail: '',
  recipientCity: '',
  recipientAddress: '',
  recipientAddressNumber: 0,
  recipientApartmentNumber: '',
  recipientComment: '',
  senderComment: '',
  chatMessages: [],
};

export const modalInitialState: Modal = {
  modalFor: '',
  orderData: orderInitialState,
};
export const shipmentModalInitialState: ShipmentModal = {
  modalFor: '',
  shipmentId: '',
};

export default function Orders() {
  const { user } = useAuthContext();
  const [offset, setOffset] = useState(0);
  const { orders, count, mutate } = getAllOrders(user, offset);
  const { clients } = getAllClients(user);
  const [modal, setModal] = useState<Modal>(modalInitialState);
  const [shipmentModal, setShipmentModal] = useState<ShipmentModal>(
    shipmentModalInitialState
  );
  const [selectedDate, setSelectedDate] = useState();
  const [clientSelectedCourierServices, setClientSelectedCourierServices] =
    useState<string[]>([]);
  const [formattedCourierServices, setFormattedCourierServices] =
    useState(courierServices);
  const [detailedView, setDetailedView] = useState(true);

  const filteredOrders = selectedDate
    ? orders?.filter((o: any) => {
        const createdAtAsDate = new Date(o.createdAt);
        const createdAtDateString = `${createdAtAsDate.getDate()}/${createdAtAsDate.getMonth()}/${createdAtAsDate.getFullYear()}`;
        let selectedDateString = '';
        if (selectedDate) {
          const selectedDateAsDate = new Date(selectedDate);
          selectedDateString = `${selectedDateAsDate.getDate()}/${selectedDateAsDate.getMonth()}/${selectedDateAsDate.getFullYear()}`;
        }

        return createdAtDateString === selectedDateString;
      })
    : orders;

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  type filterDropdownProps = {
    setSelectedKeys: any;
    selectedKeys: any;
    confirm: () => void;
    clearFilters: any;
    close: any;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: filterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Pretraga`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button style="primary" onClick={() => handleSearch(confirm)}>
              Pretraži
            </Button>
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                handleSearch(confirm);
              }}>
              Resetuj
            </Button>
          </div>
          <Button
            style="text"
            onClick={() => {
              close();
            }}>
            Zatvori
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FaSearch style={{ color: filtered ? '#1D4ED8' : undefined }} />
    ),
    onFilter: (value: string, record: TableOrder) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns =
    adminOrdersTableFunctions.includes(user?.role) && detailedView
      ? defaultColumnsAdmin(getColumnSearchProps, setModal, setShipmentModal)
      : defaultColumns(getColumnSearchProps, setModal, setShipmentModal);

  if (user?.role === 'superAdmin')
    columns.unshift({
      key: 'client',
      dataIndex: ['client', 'clientName'],
      title: 'Klijent',
      filters: clients?.map((c: Client) => ({
        text: c.clientName,
        value: c.clientName,
      })),
      onFilter: (value: string, record: TableOrder) =>
        record?.client?.clientName?.startsWith(value),
      filterSearch: true,
    });

  const updateCourierService = (
    selectedCourierService: string,
    order: TableOrder
  ) => {
    updateOrder(
      { orders, mutate, user },
      { ...order, courierService: selectedCourierService },
      order._id
    );
  };

  columns.unshift({
    key: 'courierService',
    title: 'Kurirska služba',
    filters: orders
      ?.map((o: TableOrder) => ({
        text: o.courierService,
        value: o.courierService,
      }))
      .reduce((unique: any, item: any) => {
        const exists = unique.some((u: any) => u.text === item.text);
        return exists ? unique : [...unique, item];
      }, []),
    onFilter: (value: string, record: TableOrder) =>
      record?.courierService?.startsWith(value),
    filterSearch: true,
    render: (order: TableOrder) => {
      return (
        <Select
          defaultValue={order.courierService || '-'}
          onChange={(value: any) => updateCourierService(value, order)}
          className="w-full">
          {formattedCourierServices?.length > 0
            ? formattedCourierServices?.map((courierService) => (
                <Select.Option
                  key={courierService.value}
                  value={courierService.value}>
                  {courierService.label}
                </Select.Option>
              ))
            : courierServices?.map((courierService: CourierService) => (
                <Select.Option
                  key={courierService.value}
                  value={courierService.value}>
                  {courierService.label}
                </Select.Option>
              ))}
        </Select>
      );
    },
  });

  const tools = [
    {
      key: '1',
      children: (
        <DatePicker
          className="border-0 bg-transparent shadow-transparent"
          style={{ boxShadow: 'none' }}
          onChange={(value: any) => setSelectedDate(value)}
          format={'DD/MM/YYYY'}
          placeholder="Odaberite datum"
          inputReadOnly
          showToday={false}
        />
      ),
      toolFunction: () => null,
      modifier: {
        padding: '0.25rem 0',
      },
    },
    {
      key: '2',
      children: (
        <Checkbox
          defaultChecked
          onChange={(e) => setDetailedView(e.target.checked)}>
          Detaljan pregled
        </Checkbox>
      ),
      toolFunction: () => null,
      hidden: !adminOrdersTableFunctions.includes(user?.role),
    },
    {
      key: '3',
      children: (
        <span className="flex items-center justify-between gap-2">
          <FaPlus /> Kreiraj porudžbinu
        </span>
      ),
      toolFunction: () =>
        setModal({
          modalFor: 'edit',
          orderData: orderInitialState,
          isNew: true,
        }),
      style: 'primary' as 'primary',
      hidden: !adminOrdersTableFunctions.includes(user?.role),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const client: SessionClient = user?.client;
        setClientSelectedCourierServices(
          client?.selectedCourierServices || ['BEX', 'DEXPRESS']
        );
        const formattedCourierServices = getFormattedCourierServiceForSelect(
          clientSelectedCourierServices
        );
        setFormattedCourierServices(formattedCourierServices);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const formattedCourierServices = getFormattedCourierServiceForSelect(
      clientSelectedCourierServices
    );
    setFormattedCourierServices(formattedCourierServices);
  }, [clientSelectedCourierServices]);

  return (
    <PageLayout title="Porudžbine">
      <Table
        columns={columns}
        data={filteredOrders}
        tools={tools}
        setOffset={setOffset}
        count={count}
        rowCount={10}
      />
      {modal?.modalFor === 'view' && (
        <ViewOrder
          modal={modal}
          setModal={setModal}
          modalInitialState={modalInitialState}
        />
      )}
      {modal?.modalFor === 'edit' && (
        <EditOrder
          modal={modal}
          setModal={setModal}
          modalInitialState={modalInitialState}
          orderFormHandlerData={{ orders, mutate, user }}
        />
      )}
      {modal.modalFor === 'createShipment' && (
        <CreateShipment
          modal={modal}
          setModal={setModal}
          modalInitialState={modalInitialState}
          orderFormHandlerData={{ orders, mutate, user }}
          mutate={mutate}
        />
      )}
      {shipmentModal?.modalFor === 'view' && (
        <ViewShipment
          modal={shipmentModal}
          setModal={setShipmentModal}
          modalInitialState={shipmentModalInitialState}
        />
      )}
      {modal?.modalFor === 'chat' && (
        <OrderChat
          modal={modal}
          setModal={setModal}
          modalInitialState={modalInitialState}
          orderFormHandlerData={{ orders, mutate, user }}
        />
      )}
    </PageLayout>
  );
}
