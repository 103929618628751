import axios from 'axios';
import { SERVER_URL } from '../../../config';

const updateSelectedCourierServices = async (values: any, user: any) => {
  try {
    const res = await axios.put(
      `${SERVER_URL}/clients/${user?.client?._id}`,
      {
        selectedCourierServices: values.selectedCourierServices,
      },
      {
        headers: { Authorization: `Bearer ${user?.token}` },
        withCredentials: false,
      }
    );

    const userFromSession = JSON.parse(sessionStorage.getItem('user') || '');
    userFromSession.client.selectedCourierServices =
      res?.data?.selectedCourierServices;

    sessionStorage.setItem('user', JSON.stringify(userFromSession));

    return res;
  } catch (error) {
    console.log('error on updateSelectedCourierServices: ', error);
    throw new Error('Serverska greška. Molimo pokušajte kasnije.');
  }
};

export default updateSelectedCourierServices;
