import AuthPageLayout from '../../components/layouts/AuthPageLayout';
import LoginForm from './components/LoginForm';

export default function Login() {
  return (
    <AuthPageLayout title="Prijava" logo>
      <LoginForm />
    </AuthPageLayout>
  );
}
