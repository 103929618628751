import axios from 'axios';
import { Location } from '../../../types/Location';
import { SERVER_URL } from '../../../config';

const createLocationHandler = async (data: Location, user: any) => {
  try {
    await axios.post(`${SERVER_URL}/locations`, data, {
      headers: { Authorization: `Bearer ${user?.token}` },
      withCredentials: false,
    });
  } catch (error) {
    throw new Error('Neuspešno kreiranje lokacije.');
  }
};

const createLocation = async (
  locationFormHandlerData: any,
  locationData: Location,
  user: any
) => {
  try {
    await locationFormHandlerData.mutate(
      createLocationHandler(locationData, user),
      {
        optimisticData: [...locationFormHandlerData.locations, locationData],
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
      }
    );
  } catch (error) {
    console.error('Create Location Error: ', error);
    throw new Error('Neuspešno kreiranje lokacije.');
  }
};

export default createLocation;
